import { HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, Subscription } from 'rxjs';
import { AtsService } from 'src/app/services/ats.service';
import { LanguageTranslateService } from 'src/app/services/language-translate.service';
import * as Global from 'src/app/shared/globals';

@Component({
  selector: 'app-requisition',
  templateUrl: './requisition.component.html',
  styleUrls: ['./requisition.component.css']
})
export class RequisitionComponent {
  requisitionList!:any[];
  // paginationOptions: PositionOptions = Global.resetPaginationOption();
  searchkey = new FormControl('');
  requistionId:any;
  resData:any;
  applicationLink:any;
  companyData!:any;
  companyBranchMaster!:any[];
  translations: any = [];
  currentLanguage$?: Subscription;
  languages: any[] = [];
  lang: string;
  constructor(
    private atsService: AtsService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private langService: LanguageTranslateService,
    private route: ActivatedRoute,
   
  ){
  
  }
  
  ngOnInit(): void {
    this.route.paramMap.subscribe((params: any) => {
      console.log(params)
      this.lang = params.get('lang');
    });
    this.spinner.show();
    this.fetchRequistionList(this.searchkey.value);
    this.searchkey.valueChanges.pipe(debounceTime(700)).subscribe(value => {
      this.fetchRequistionList(value);
    });
   
    this.currentLanguage$ = this.langService.currentLanguage$.subscribe(async () => {
      return new Promise<boolean>(async (resolve, reject) => {
        await this.langService.loadTranslations('ats', 'RequisitionComponent')?.then((res: any) => {
          this.translations = res;
          resolve(true);
        }).catch((err: any) => {
          console.error(`Failed to load translations`, err);
          reject(false);
        });
      })
    });
  }

  
  
  fetchRequistionList( searchkey: any) {
    return new Promise((resolve, reject) => {
      this.atsService.getRequistionList({
        params: new HttpParams()
        .set('filter', 'active')
        .set('searchkey', searchkey || '')
      }).subscribe((res: any) => {
        // console.log(res)
        const data = res?.data ?? {};
        // console.log(data)
        if (res?.status == 200) {
          this.requisitionList = data?.listData ?? [];
          
          this.spinner.hide();
         
          resolve(true);
        } else {
          this.toastr.error(res?.message);
          resolve(false);
        }
      })
    })
  }
  
    routeToPage(){
       this.router.navigate([this.lang, 'ats','requistion','create'])
     }
  
     edit(tempData:any){
      console.log(tempData)
      const id = tempData?.id;
      this.router.navigate([this.lang, 'ats', 'requistion', 'edit', id ])
        
     }
  
     jobDetails(tempData:any){
      console.log(tempData)
      const id = tempData?.id;
      this.router.navigate([this.lang, 'ats', 'requistion', 'job-details', id ])
        
     }
  
     copyLink(linkData:any){
      console.log(linkData)
      const encodedUrl = encodeURIComponent(linkData);
      console.log(encodedUrl)
      navigator.clipboard.writeText("localhost:4200/en/recruitment-form/"+encodedUrl);
      console.log( navigator.clipboard.writeText("localhost:4200/en/recruitment-form/"+encodedUrl))
      this.toastr.success('Link Copied!');
     }
  
}
