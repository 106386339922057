import { Component, Inject } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, UntypedFormArray, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AtsService } from 'src/app/services/ats.service';
import * as Global from 'src/app/shared/globals';

@Component({
  selector: 'app-shortlist-dialog',
  templateUrl: './shortlist-dialog.component.html',
  styleUrls: ['./shortlist-dialog.component.css']
})
export class ShortlistDialogComponent {
  reuistionId: any;
  interviewTempList:any[]=[];
  interviewSheduleForm!: FormGroup;
  applicantDetails:any;
  applicantTemp:any[] = [];
  flag:boolean;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private atsService: AtsService,
    private toastr: ToastrService,
    public formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<ShortlistDialogComponent>,
  ){
    this.interviewSheduleForm = this.formBuilder?.group({
        interview_template: this.formBuilder.array([])
    })
  }



initTemplateRows(type: any, data: any = {}) {
  console.log(data);
  switch (type) {
      case 'interview_template': {
          const formattedDate = data?.date 
              ? new Date(data.date).toISOString().split('T')[0] 
              : null;

          const formattedTime = data?.time 
              ? new Date(data.time).toISOString().slice(11, 16) 
              : null;

          return this.formBuilder.group({
              interview_id: [data?.interview_id ?? null, Validators.compose([])],
              interviewer_name: [data?.interviewer_name ?? null, Validators.compose([])],
              interview_title: [data?.interview_title ?? null, Validators.compose([])],
              location: [data?.location ?? null, Validators.compose([])],
              date: [formattedDate, Validators.compose([])], 
              time: [formattedTime, Validators.compose([])], 
              // remarks: [data?.remarks ?? null, Validators.compose([])],
          });
      }
      default: {
          return this.formBuilder.group({});
      }
  }
}


addTemplateRows(type: any, data: any = {}) {
  console.log(data)
  const control = <UntypedFormArray>this.interviewSheduleForm.get(type);
  control.push(this.initTemplateRows(type, data));

}

clearTemplateRows() {
const control = <UntypedFormArray>this.interviewSheduleForm.get('interview_template');
if (control) {
    control.clear();
}
}

  ngOnInit(): void {
    console.log(this.data);
    this.reuistionId = this.data?.requistion_id;
    this.fetchInterviewTemplate();
  }

  

  fetchInterviewTemplate( ) {
    return new Promise((resolve, reject) => {
      this.atsService.intervierwTemplateList(this.reuistionId).subscribe((res: any) => {
         console.log(res)
         const data = res?.data.map((result:any)=>{
          if(result?.id === this.data?.applicant_id){
            console.log(result);
            this.applicantDetails = result;
            this.applicantTemp = result?.interviews ?? [];
          }
         });
         
        // console.log(data)
        if (res) {
          this.interviewTempList = this.applicantTemp ?? [];
          console.log(this.interviewTempList)
        
          this.spinner.hide();
          this.edit()
         
          resolve(true);
        } else {
          this.toastr.error(res?.message);
          resolve(false);
        }
      })
    })
  }

  edit( ){
    this.interviewTempList?.forEach((element:any)=>{
       console.log(element)
      //  this.flag = element?.isUserScheduleInterview;
      //  console.log(this.flag)
        let data:any = {
          interview_id: element?.id,
          interview_title: element?.stage_title,
          location: element?.location ?? "",
          date: element?.date ?? "",
          time: element?.date ?? "",
          isUserScheduleInterview: element?.isUserScheduleInterview,
          place_status: element?.place_status,
          interviewer_name: element?.interviewerDetails?.emp_first_name + ' ' + element?.interviewerDetails?.emp_last_name 
        }
        this.addTemplateRows('interview_template', data);
        })
     }

     updateTemplate(event: any, data:any, i:any) {
      console.log(i)
      console.log(data)
      const interviewTemplateArray = this.interviewSheduleForm.get('interview_template') as FormArray;

  interviewTemplateArray.controls.forEach((group: AbstractControl, index: number) => {
    const formGroup = group as FormGroup; // Cast each control to FormGroup
    Object.keys(formGroup.controls).forEach((field) => {
      const fieldControl = formGroup.get(field);
      if (fieldControl?.invalid) {
        console.log(`Invalid field: interview_template[${index}].${field}`, fieldControl.errors);
      }
    });
  });
       this.interviewSheduleForm.markAllAsTouched();
      setTimeout(function () {
          Global.scrollToQuery("p.error-element");
      }, 100)
      console.log('updtW');
      console.log(this.interviewSheduleForm.value?.interview_template.at(i)?.date);
      console.log(this.interviewSheduleForm.value?.interview_template.at(i)?.location)
      if (this.interviewSheduleForm.valid) {
        console.log('unu');
        const dateValue = this.interviewSheduleForm.value?.interview_template.at(i)?.date; // e.g., "2024-11-30"
        const timeValue = this.interviewSheduleForm.value?.interview_template.at(i)?.time;
           let payload = {
            interview_id: data?.id,
            date: dateValue && timeValue ? `${dateValue} ${timeValue}:00` : null,
            location: this.interviewSheduleForm.value?.interview_template.at(i)?.location
           };
          const button = event.currentTarget as HTMLButtonElement;
          button.classList.add('btn-loading');
  
          this.atsService.interviewShedule(payload).subscribe((res:any) => {
              console.log(res)
              if (res.status ==  200) {
              this.toastr.success("Interview Updated Successfully");
              this.dialogRef.close(true)
                 
            }  else if (res.status == 400) {
              this.toastr.error(Global.showValidationMessage(res.val_msg));
            }
          
      })
  
    }
  }

      closeDialog(){
        this.dialogRef.close();
      }

      downloadCvFile(filepath:any){
        console.log(filepath)
        const fileName = 'Resume'
        this.atsService.downloadFileDirectly(filepath, 'Resume.pdf');
      }

     

}
