import {
  AbstractControl, FormArray,
  FormControl,
  FormGroup,
  UntypedFormGroup,
  ValidatorFn,
} from '@angular/forms';
import * as moment from 'moment';
import { environment } from './../../environments/environment';
import { saveAs } from 'file-saver';
import { Toolbar } from 'ngx-editor';
export const AppName = 'The Seamless HR Solution';
export const ASSET_URL = environment.ASSET_URL;

export const toolbar: Toolbar = [
  ['bold', 'italic'],
  ['underline', 'strike'],
  ['code', 'blockquote'],
  ['ordered_list', 'bullet_list'],
  ['link','image'],
  ['text_color', 'background_color'],
];

export function fileSizeValidator(file: any, maxSize: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (file) {
      const fileSizeInMB = file.size / 1024; // Convert to MB

      if (fileSizeInMB > maxSize) {
        return { fileSize: true, maxSize: maxSize };
      }
    }
    return null;
  };

  
}



export function isEmpty(obj: Record<string, any>) {
  return Object.keys(obj).length === 0;
}
export function maxFileSize(file_size: any = 500) {
  let max_size: any = 0;
  if (file_size > 500) {
    max_size = 500;
  } else {
    max_size = file_size;
  }
  return max_size;
}
export function isInputRuleValid(
  formGroup: FormGroup,
  control: any,
  rule: any
) {
  let valid: boolean = true;

  let cntrls = control.split('.');
  let fgroupcontrols: any = formGroup;
  if (cntrls.length > 1) {
    cntrls.forEach((c: any) => {
      fgroupcontrols = fgroupcontrols.controls[c];
    });
  } else {
    fgroupcontrols = fgroupcontrols.controls[control];
  }

  if (rule instanceof Array) {
    rule.forEach((r) => {
      if (
        fgroupcontrols.hasError(r) &&
        (fgroupcontrols.touched || fgroupcontrols.dirty)
      ) {
        valid = false;
      }
    });
  } else {
    if (
      fgroupcontrols.hasError(rule) &&
      (fgroupcontrols.touched || fgroupcontrols.dirty)
    ) {
      valid = false;
    }
  }
  return valid;
}
export function isValidationAvailable(
  formGroup: FormGroup,
  control: any,
  rules: any
) {
  const formControl: any = formGroup.get(control);

  if (formControl) {
    const validator =
      formControl.validator && formControl.validator(new FormControl());

    if (validator && validator[rules]) {
      return true;
    }
  }

  return false;
}
export function scrollToQuery(query: any) {
  let $_errFormControl = document?.querySelectorAll(query);
  if ($_errFormControl?.length > 0) {
    const firstErr: Element = $_errFormControl[0];
    firstErr.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
}
export function formatHHmm(time: any){
  // Check if time is falsy or 0, return '00:00'
   if (!time) {
    return '00:00';
  }

  // If time is a decimal (e.g., 0.2), treat it as minutes (e.g., 0.2 -> 2 minutes)
  const totalMinutes = parseFloat(time) * 60; // Convert fractional hours to minutes
  const hours = Math.floor(totalMinutes / 60);
  const minutes = Math.round(totalMinutes % 60);
  
// Convert decimal part to minutes directly

  // Return formatted time ensuring two digits for both hours and minutes
  return moment.utc().hours(hours).minutes(minutes).format('HH:mm');
};
export function showValidationMessage(result: any[]) {
  for (const key in result) {
    if (Object.prototype.hasOwnProperty.call(result, key)) {
      const element = result[key];
      if (element.message) {
        return element.message;
      }
    }
  }
  return 'Validation Error: Please check all the fields correctly';
}
export const monthMaster = [
  {
    index: 0,
    value: 0,
    monthLabel: '01',
    description: 'January',
    days: 31,
    sf: 'Jan',
  },
  {
    index: 1,
    value: 1,
    monthLabel: '02',
    description: 'February',
    days: 28,
    sf: 'Feb',
  },
  {
    index: 2,
    value: 2,
    monthLabel: '03',
    description: 'March',
    days: 31,
    sf: 'Mar',
  },
  {
    index: 3,
    value: 3,
    monthLabel: '04',
    description: 'April',
    days: 30,
    sf: 'Apr',
  },
  {
    index: 4,
    value: 4,
    monthLabel: '05',
    description: 'May',
    days: 31,
    sf: 'May',
  },
  {
    index: 5,
    value: 5,
    monthLabel: '06',
    description: 'June',
    days: 30,
    sf: 'Jun',
  },
  {
    index: 6,
    value: 6,
    monthLabel: '07',
    description: 'July',
    days: 31,
    sf: 'Jul',
  },
  {
    index: 7,
    value: 7,
    monthLabel: '08',
    description: 'August',
    days: 31,
    sf: 'Aug',
  },
  {
    index: 8,
    value: 8,
    monthLabel: '09',
    description: 'September',
    days: 30,
    sf: 'Sep',
  },
  {
    index: 9,
    value: 9,
    monthLabel: '10',
    description: 'October',
    days: 31,
    sf: 'Oct',
  },
  {
    index: 10,
    value: 10,
    monthLabel: '11',
    description: 'November',
    days: 30,
    sf: 'Nov',
  },
  {
    index: 11,
    value: 11,
    monthLabel: '12',
    description: 'December',
    days: 31,
    sf: 'Dec',
  },
];
export function getMonthValue(index: any) {
  return (
    monthMaster.find((obj) => {
      return obj.index == index;
    }) ?? null
  );
}
export function showServerErrorMessage(err: any) {
  if (err.status == 401) {
    return err.error.message;
  } else {
    return 'Internal server error occured. Please try again later';
  }
}
export function resetForm(
  formGroup: FormGroup,
  resetFormGroup: boolean = true
) {
  if (resetFormGroup) formGroup.reset();

  for (const key in formGroup.controls) {
    if (Object.prototype.hasOwnProperty.call(formGroup.controls, key)) {
      const element = formGroup.controls[key];

      element.markAsUntouched();
      element.markAsPristine();
    }
  }
}
export function getGenderMaster() {
  return [
    { value: 'm', description: 'Male' },
    { value: 'f', description: 'Female' },
    { value: 't', description: 'Transgender' },
    { value: 'o', description: 'Other' },
  ];
}

export const relationMaster = [
  { value: 'mother', gender: 'F', description: 'Mother' },
  { value: 'father', gender: 'M', description: 'Father' },
  { value: 'daughter', gender: 'F', description: 'Daughter' },
  { value: 'son', gender: 'M', description: 'Son' },
  { value: 'sister', gender: 'F', description: 'Sister' },
  { value: 'brother', gender: 'M', description: 'Brother' },
  { value: 'aunt', gender: 'F', description: 'Aunt' },
  { value: 'uncle', gender: 'M', description: 'Uncle' },
];

export function resetFormGroupArrRow(formGroup: FormGroup, type: any) {
  const control = <FormArray>formGroup.get(type);
  control.clear();
}

export function getFormGroupArray(formGroup: FormGroup, type: any) {
  return (formGroup.get(type) as FormArray).controls;
}
export function removeFormGroupArrRow(
  formGroup: FormGroup,
  type: any,
  index: number
) {
  const control = <FormArray>formGroup.get(type);
  control.removeAt(index);
}

export const maritalStatusMaster = [
  { value: 'unmarried', description: 'Un-Married' },
  { value: 'married', description: 'Married' },
  { value: 'divorced', description: 'Divorced' },
  { value: 'separated', description: 'Separated' },
  { value: 'widowed', description: 'Widowed' },
];

export const bloodGroupMaster = [
  { value: 'A+', description: 'A+ (A positive)' },
  { value: 'A−', description: 'A− (A negative)' },
  { value: 'B+', description: 'B+ (B positive)' },
  { value: 'B−', description: 'B− (B negative)' },
  { value: 'AB+', description: 'AB+ (AB positive)' },
  { value: 'AB−', description: 'AB− (AB negative)' },
  { value: 'O+', description: 'O+ (O positive)' },
  { value: 'O−', description: 'O− (O negative)' },
];

export const religionMaster = [
  { value: 'hindu', description: 'Hindu' },
  { value: 'christian', description: 'Christian' },
  { value: 'buddhist', description: 'Buddhist' },
  { value: 'muslim', description: 'Muslim' },
  { value: 'jewish', description: 'Jewish' },
  { value: 'sikh', description: 'Sikh' },
  { value: 'no_religion', description: 'No Religion' },
];

export function getYearsUntilFutureDate(prevDate: Date): number {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const prevYear = new Date(prevDate).getFullYear();
  const yearsUntilPrevDate = currentYear - prevYear;

  return yearsUntilPrevDate;
}
export function getYearRange(prevYear: number, lang: string): object {
  const currentYear = new Date().getFullYear();
  const previousYears = prevYear;
  const futureYears = 0;
  const years = Array.from(
    { length: previousYears + futureYears + 1 },
    (_, index) => currentYear - previousYears + index
  ).map((year: number) => {
    if (lang == 'ar') {
      return getArYear(year);
    } else {
      return getEnYear(year);
    }
  })

  return years;
}
export function getArYear(year: number) {
  return year?.toString().replace(/[0-9]/g, (d: any) => '٠١٢٣٤٥٦٧٨٩'[parseInt(d)]);
}
export function getEnYear(year: number) {
  return year?.toString().replace(/[٠١٢٣٤٥٦٧٨٩]/g, (d: any) => '0123456789'.charAt('٠١٢٣٤٥٦٧٨٩'.indexOf(d)));
}
export function getMonthRange(): object {
  const months = Array.from({ length: 12 }, (_, index) => {
    const date = new Date(2000, index, 1);
    return date.toLocaleString('en-US', { month: 'long' });
  });
  return months;
}
export function getCurrentMonth(months: any): number {
  const currentMonthIndex = new Date().getMonth();
  return months[currentMonthIndex];
}
export function getCurrentYear(years: any[] = []): number {
  const currentYear = new Date().getFullYear();
  const currentYearIndex = years.indexOf(currentYear);
  if (currentYearIndex !== -1) {
    return years[currentYearIndex];
  } else {
    return currentYear;
  }
}
export function getNextMonth(startDate: any): Date {
  const currentDate = new Date(startDate);
  const nextMonth = new Date(
    currentDate?.getFullYear(),
    currentDate?.getMonth() + 1,
    1
  );
  return nextMonth;
}
export function checkShiftTypeExist(shiftType: any): boolean {
  const shiftArray = [
    'H',
    'OT',
    'L',
    'ERL',
    'SKL',
    'MDL',
    'MTL',
    'PTL',
    'ANL',
    'AWP',
    'UWP',
    'LE1',
    'LE2',
    'LP1',
    'LP2',
    'WO',
  ];
  if (shiftArray?.includes(shiftType)) {
    return true;
  }
  return false;
}
export function generateCalenderObject(dynamicTitle: any, attendance: any, dynamicColor: any, dynamicBackgroundColor: any, dynamicClassName: any): object {
  let calendarEvents = {};
  calendarEvents = {
    title: dynamicTitle,
    start: attendance?.attendance_date,
    allDay: false,
    textColor: dynamicColor,
    backgroundColor: dynamicBackgroundColor,
    className: dynamicClassName,
    eventContent: (arg: any) => {
      const { event } = arg;
      return {
        html: `<div style="background-color: ${event?.backgroundColor};">${event?.title}</div>`
      };
    },
    extendedProps: {
      'attendance': attendance,
    },
  }

  return calendarEvents;
}
export function msToTime(ms: any) {
  // Calculate hours and minutes
  const hours = Math.floor(ms / (1000 * 60 * 60));
  const minutes = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60));
  const time_string = hours + "H : " + minutes + "M";
  const formated_time_string = formatTime(time_string);

  // Format the output
  return formated_time_string;
}
export function formatTime(timeString: any) {
  // Split the input string into hours and minutes
  const [hours, minutes] = timeString?.replace(/M$/, '')?.split('H : ');

  // Ensure hours and minutes are two digits
  const formattedHours = hours?.padStart(2, '0');
  const formattedMinutes = minutes?.padStart(2, '0');

  // Return the formatted time
  return `${formattedHours}H : ${formattedMinutes}M`;
}
export function formatDatePmAm(time: any) {
  const format_time = moment(time, 'HH:mm')?.format('hh:mm A');
  return format_time;
}
export function formatHours(time: any) {
  const format_time = moment(time, 'HH:mm')?.format('hh:mm');
  return format_time;
}
export function formatedTime(timeString: string) {
  const time = moment(timeString, "HH:mm");
  const formattedTime = time.format("HH[H] : mm[M]");
  return formattedTime;
}
export function formatedDate(date: any) {
  const format_date = moment(date)?.format('DD MMM YYYY');
  return format_date;
}
export function toLocalDate(date: any) {
  let utcDate = new Date(date);
  let hh = utcDate.getUTCHours();
  let mm = utcDate.getUTCMinutes();
  let ss = utcDate.getUTCSeconds();
  let yr = utcDate.getUTCFullYear();
  let mnth = utcDate.getUTCMonth();
  let day = utcDate.getUTCDate();
  let time = new Date(yr, mnth, day, hh, mm, ss)
  let localTime = new Date(time);
  return localTime;
}
export function savePdfFile(fileName: string, res: any, fileType: string): void {
  const blob = new Blob([new Uint8Array(res)], { type: fileType });
  saveAs(blob, fileName);
}
export interface LangMaster {
  label: string;
  value: string;
}
export function languageMaster(): LangMaster[] {
  const lang = [
    { label: 'English', value: 'en' },
    { label: 'Arabic', value: 'ar' },
  ];
  return lang;
}
export function checkOrigin(originString: string) {
  if (window.location.href.includes(originString)) return true;
  return false;
}
export interface Month {
  value: number;
  description: string;
}
export const engMasterMonths: Month[] = [
  { value: 0, description: 'January' },
  { value: 1, description: 'February' },
  { value: 2, description: 'March' },
  { value: 3, description: 'April' },
  { value: 4, description: 'May' },
  { value: 5, description: 'June' },
  { value: 6, description: 'July' },
  { value: 7, description: 'August' },
  { value: 8, description: 'September' },
  { value: 9, description: 'October' },
  { value: 10, description: 'November' },
  { value: 11, description: 'December' },
];
export const arMasterMonths: Month[] = [
  { value: 0, description: 'كانون الثاني/يناير' },
  { value: 1, description: 'شباط/فبراير' },
  { value: 2, description: 'مارس' },
  { value: 3, description: 'نيسان/أبريل' },
  { value: 4, description: 'مايو' },
  { value: 5, description: 'حزيران/يونيو' },
  { value: 6, description: 'تموز/يوليو' },
  { value: 7, description: 'آب/أغسطس' },
  { value: 8, description: 'أيلول/سبتمبر' },
  { value: 9, description: 'أكتوبر' },
  { value: 10, description: 'تشرين الثاني/نوفمبر' },
  { value: 11, description: 'كانون الأول/ديسمبر' },
];
export const months: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
export interface LeaveMaster {
  value: string,
  description: string
}
export const leaveStatusMaster: LeaveMaster[] = [
  { value: 'approved', description: "Approved" },
  { value: 'rejected', description: "Rejected" },
  { value: 'pending', description: "Pending" },
];

export const advanceStatusMaster: LeaveMaster[] = [
  { value: 'active', description: "Approved" },
  { value: 'rejected', description: "Rejected" },
  { value: 'pending', description: "Pending" },
];

export const _yearlyHolidayIcon: { slug: string, icon: string, description: string }[] = [
  { slug: 'holiday_icon_1', icon: 'holiday-icon1.png', description: 'holiday icon description' },
  { slug: 'holiday_icon_2', icon: 'holiday-icon2.png', description: 'holiday icon description' },
  { slug: 'holiday_icon_3', icon: 'holiday-icon3.png', description: 'holiday icon description' },
  { slug: 'holiday_icon_4', icon: 'holiday-icon4.png', description: 'holiday icon description' },
  { slug: 'holiday_icon_5', icon: 'holiday-icon5.png', description: 'holiday icon description' },
  { slug: 'holiday_icon_6', icon: 'holiday-icon6.png', description: 'holiday icon description' },
  { slug: 'holiday_icon_7', icon: 'holiday-icon7.png', description: 'holiday icon description' },
  { slug: 'holiday_icon_8', icon: 'holiday-icon8.png', description: 'holiday icon description' },
  { slug: 'holiday_icon_9', icon: 'holiday-icon9.png', description: 'holiday icon description' },
  { slug: 'holiday_icon_10', icon: 'holiday-icon10.png', description: 'holiday icon description' },
  { slug: 'holiday_icon_11', icon: 'holiday-icon11.png', description: 'holiday icon description' },
  { slug: 'holiday_icon_12', icon: 'holiday-icon12.png', description: 'holiday icon description' },
  { slug: 'holiday_icon_13', icon: 'holiday-icon13.png', description: 'holiday icon description' },
  { slug: 'holiday_icon_14', icon: 'holiday-icon14.png', description: 'holiday icon description' },
  { slug: 'holiday_icon_15', icon: 'holiday-icon15.png', description: 'holiday icon description' },
  { slug: 'holiday_icon_16', icon: 'holiday-icon16.png', description: 'holiday icon description' },
  { slug: 'holiday_icon_17', icon: 'holiday-icon17.png', description: 'holiday icon description' },
  { slug: 'holiday_icon_18', icon: 'holiday-icon18.png', description: 'holiday icon description' },
  { slug: 'holiday_icon_19', icon: 'holiday-icon19.png', description: 'holiday icon description' },
  { slug: 'holiday_icon_20', icon: 'holiday-icon20.png', description: 'holiday icon description' },
];

export function resetPaginationOption() {
  return {
    hasNextPage: false,
    hasPrevPage: false,
    limit: 10,
    nextPage: null,
    page: 1,
    pagingCounter: 1,
    prevPage: null,
    totalDocs: 0,
    totalPages: 1,
  };
}
