<div class="info-fluid height100 "> 
    <div class="card info_box">
      <div class="p-15 border-bottom d-flex justify-content-between align-items-center">
        <a class="backbtn" href="javascript:void(0)" (click)="backToApprovalList()" ><i class="bi bi-chevron-left"></i> Back to My Job</a>
        <!-- <button style="margin-left: 80%;" (click)="openDialog()" *ngIf="approveFlag == false"> Approve/Reject</button> -->
        <button  class="btn button-solide" (click)="openDialog()" *ngIf="approveFlag == true"> Approve/Reject</button>
      </div>  
  
      <div class="card-body p-15 position-relative">
        <h2 class="pagetitle mb-3"></h2>
        <app-navbar></app-navbar>
        <div class="tab-content plain-tcontent position-relative" id="nav-tabContent">
          <div class="tab-pane fade active show" id="nav-jobdtls" role="tabpanel" aria-labelledby="nav-jobdtls-tab"> 
              <ng-container *ngIf="requistionData">
              <div class="row">
                <div class="col-lg-6">
                  <div class="job-view">
                    <div class="d-flex jobdtls-top">
                      <!-- <figure ><img alt="" src="https://dev14.ivantechnology.in/cartesian/public/no_logo.png" class="ng-star-inserted"></figure> -->
                      <div>
                        <p><span>ID: {{requistionData?.c_id}}</span><span>Open Date: {{requistionData?.date | date:'yyyy-MM-dd'}}</span></p>
                        <h3>{{requistionData?.job_title}}</h3>
                        <h4> {{requistionData?.job_type | capitalizeFirstLetter}}</h4>
                        
                      </div>
                    </div>
                    <hr>
                    <h3 class="subhead">Job details</h3>
                    <div class="dtls-rpt" *ngIf="requistionData?.job_type" >
                      <div class="d-flex align-items-center iconhead">
                        <img src="assets/images/watch.png" alt="">
                        <span>Job type</span>
                      </div>
                      <div class="lightgbtn" > {{requistionData?.job_type | capitalizeFirstLetter}} <i class="bi bi-check-lg"></i></div>
                    </div>
          
                    <div class="dtls-rpt" *ngIf="requistionData?.shift" >
                      <div class="d-flex align-items-center iconhead">
                        <img src="assets/images/watch.png" alt="">
                        <span>Shift</span>
                      </div>
                      <div class="lightgbtn"> {{requistionData?.shift | capitalizeFirstLetter}} <i class="bi bi-check-lg"></i></div>
                    </div>
          
                    <div class="dtls-rpt" *ngIf="requistionData?.experience">
                      <div class="d-flex align-items-center iconhead">
                        <img src="assets/images/salary.png" alt="">
                        <span>Experience</span>
                      </div>
                      <div class="graybg"> {{requistionData?.experience}}&nbsp;Year </div>
                    </div>
          
                    <div class="dtls-rpt" *ngIf="requistionData?.work_mode">
                      <div class="d-flex align-items-center iconhead">
                        <img src="assets/images/salary.png" alt="">
                        <span>Work Mode</span>
                      </div>
                      <div class="graybg"> {{requistionData?.work_mode}} </div>
                    </div>
          
                    <div class="dtls-rpt" *ngIf="requistionData?.salary">
                      <div class="d-flex align-items-center iconhead">
                        <img src="assets/images/salary.png" alt="">
                        <span>Salary</span>
                      </div>
                      <div class="graybg"> {{requistionData?.salary}}&nbsp;AED </div>
                    </div>
                    <div class="dtls-rpt" *ngIf="requistionData?.job_location">
                      <div class="d-flex align-items-center iconhead">
                        <img src="assets/images/location.png" alt="">
                        <span>Job Location</span>
                      </div>
                      <p>{{requistionData?.job_location}}</p>
                    </div>
                    
                    <hr>
                    <h3 class="subhead mb-2">Job Description:</h3>
                    <div class="shortdesc" [innerHTML]="requistionData?.job_description">
                    </div>
                  </div>
                </div>
                  <div class="col-lg-6">
                    <div class="requisitionslider">
                        <div class="row">
                          <div class="col-lg-6">
                            <div class="slidepro">
                              <h2>Approval process</h2>
                              
                              <ul class="procelist">
                              <ng-container *ngFor="let item of requistionData?.req_approval; let i = index">
                                <li>
                                  
                                  <span class="greencheck">
                                      <span class="checkbox-container gr_check" *ngIf="item?.status == 'approved'"><i class="bi bi-check-lg"></i></span>
                                      <span class="checkbox-container pe_check" *ngIf="item?.status == 'pending'" ><i class="bi bi-exclamation"></i></span>
                                      <span class="checkbox-container re_check" *ngIf="item?.status == 'rejected'" ><i class="bi bi-x"></i></span>
                                  </span>
                                  <p><span>STAGE {{i+1}}</span></p>
                                  <h2>{{item?.stage_title}}</h2>
                                  <p>{{item?.approver_det?.emp_first_name }}&nbsp; {{item?.approver_det?.emp_last_name }}</p>
                                </li>
                              </ng-container>
                              </ul>
                             
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="slidepro">
                              <h2>Interview process</h2>
                             
                              <ul class="procelist">
                                <ng-container *ngFor="let item of requistionData?.req_interview; let i = index">
                                  <li>
                                    <!-- <span class="grecheck"><i class="bi bi-check-lg"></i></span> -->
                                    <span class="greencheck">
                                      <!-- <input id="apply_terms" type="checkbox" name="apply_terms"> -->
                                      <span class="checkbox-container gr_num"> {{i + 1}}</span>
                                    </span>
                                    <p><span>STAGE {{i+1}}</span></p>
                                    <h2>{{item?.stage_title}}</h2>
                                    <h6>{{item?.interviewer_det?.emp_first_name}}&nbsp; {{item?.interviewer_det?.emp_last_name}}</h6>
                                    <p>{{item?.stage_desc ?? ""}}</p>
                                  </li>
                                </ng-container>
                              </ul>
                              
                            </div>
                          </div>
                        </div>
                        <style>
                         
                          .slide-label {
                            display: block;
                            cursor: pointer;
                          }
                        </style>
                    </div>
                  </div>
                </div>
                
              
              </ng-container>
            </div>
           
        </div> 
      </div>
    </div>
  </div> 
  
