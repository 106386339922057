
    
        <div class="p-15 border-bottom modal-header bg-light">
            <div class="modal-text">
                <h5 class="derktext left-line mb-0 static-text">Hire/Reject Form</h5>
            </div>
            <button class="btn-close btn ms-4 ms-auto"><i class="bi bi-x-lg" (click)="closeDialog()"></i></button>
        </div>
   
        <form [formGroup]="hiringForm">
          
                <div class="p-15 pt-4 modal-body s">
                    <div class="risection">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="mb-3 form-group">
                                    <h2>{{this.data?.applicantData?.fname}}&nbsp; {{this.data?.applicantData?.lname}}</h2>
                                </div>
                                </div>
                            </div>

                            <div class="col-md-12">
                                <div class="mb-3 form-group">    
                                    <label for="" class="form-label">Select Hire/Reject <span class="text-danger">*</span></label>
                                    <ngx-select-dropdown formControlName="hiring_status" [options]="hiringStatusMaster" class="form-control" [config]="{search: true, placeholder: 'Select One', 'height': '200px'}"></ngx-select-dropdown>
                                    <div *ngIf="hiringForm.controls['hiring_status'].invalid && (hiringForm.controls['hiring_status'].dirty || hiringForm.controls['hiring_status'].touched)">
                                        <small class="text-danger">This Field Is Required.</small>
                                    </div>
                                </div>
                            </div>

                             <div class="col-md-12">
                                <div class="mb-3 form-group">
                                <label for="" class="form-label">Remarks <span class="text-danger">*</span></label>
                                 <textarea class="inputgray form-control" style="height: 100px;" formControlName="remarks" ></textarea>
                                 <div *ngIf="hiringForm.controls['remarks'].invalid && (hiringForm.controls['remarks'].dirty || hiringForm.controls['remarks'].touched)">
                                    <small class="text-danger">This Field Is Required.</small>
                                 </div>
                                 </div>
                                 
                            </div>
                          
                        </div>
                    </div>
                
                

                <div class="modal-footer p-15 py-2 border-top">
                    <button class="btn button-solide" (click)="selectApplicant($event)"  ><span>Hire</span></button>
                    <button class="btn button-solide gray-btn" (click)="selectApplicant($event)" >Reject</button>
                </div>
          
        </form>
