import {
  HttpClient,
  HttpContext,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, firstValueFrom } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Response } from '../http-handler/interfaces/response';
import * as Global from 'src/app/shared/globals';

@Injectable({
  providedIn: 'root',
})
export class WebService {
  private readonly Base_Url = environment.apiUrl;
  readonly BASE_HREF = environment.BASEHREF;
  readonly BASE_ATS_URL!:string;
  readonly BASE_HREF_ATS!:string;
  constructor(private http: HttpClient) {
    this.BASE_ATS_URL = environment.apiAtsUrl;
    this.BASE_HREF_ATS = environment.BASEHREF_ATS
  }

  get(url: string) {
    return this.http.get<any>(`${this.Base_Url}/${this.BASE_HREF}/${url}`);
  }

  async post(url: string, payload: any) {
      return await firstValueFrom(
        this.http.post<any>(`${this.Base_Url}/${this.BASE_HREF}/${url}`, payload)
      ).then((response:Response) => {
        if (response?.status !== 'success') {          
          if(response?.val_msg){
            const {val_msg} = response;

            for (const key in val_msg) {
              if (Object.prototype.hasOwnProperty.call(val_msg, key)) {
                const element = val_msg[key];
                if (element.message) {
                  return this.handleError(
                    element.message || 'Something went wrong!'
                  );
                }
              }
            }
            return 'Validation Error: Please check all the fields correctly';
          }
          return this.handleError(
            response?.error || response?.message || response?.code || 'Something went wrong!'
          );
        }
        return response;
      }).catch(this.handleError);
  }

  simpleGet(url: string, payload: Object = {}) {
    return this.http.post(`${this.Base_Url}/${this.BASE_HREF}/${url}`, payload, {
      responseType: 'blob',
      observe: 'response',
    });
  }

  postFormData(url: string, payload: any) {
    var formData = new FormData();
    for (var key in payload) {
      formData.append(key, payload[key]);
    }
    // return this.http.post<any>(`${this.Base_Url}/${url}`, formData);
    return this.post(url, formData);
  }
  postMultiFile(url: any, submit_data: any) {
    // const webservice_path = Global.BACKEND_URL;
    let form_data = new FormData();
    for (const key in submit_data) {
      if (submit_data.hasOwnProperty(key)) {
        // console.log(submit_data[key], 'arr d');
        if (Array.isArray(submit_data[key])) {
          // Handle multiple files for the 'submit_data' key
          const files: File[] = submit_data[key];
          for (let i = 0; i < files.length; i++) {
            form_data.append(`${key}`, files[i], files[i].name);
          }
        } else {
          form_data.append(key, submit_data[key]);
        }
      }
    }
    return this.post(url, form_data);
  }
  postFormDataNested(url: string, payload: any) {
    var formData = new FormData();
    for (var key in payload) {
      if (key == 'eighty_c_investments') {
        let i = 0;
        let rawElemt = payload[key];
        payload[key] = payload[key].filter((x: any) => x.children.length > 0);
        for (let index in payload[key]) {
          let p_head = rawElemt.find(
            (x: any) => x.name == payload[key][index].parent_id
          );
          formData.append(`declaration_p_head[${index}]`, p_head?.label);
          formData.append(
            `declaration_items[${index}]`,
            payload[key][index].label
          );
          formData.append(
            `declaration_key[${index}]`,
            payload[key][index].name
          );
          payload[key][index].children.forEach(
            (element: any, SubIndex: any) => {
              formData.append(
                `declaration_sub_label[${index}][${SubIndex}]`,
                element.label
              );
              formData.append(
                `declaration_sub_amount[${index}][${SubIndex}]`,
                element.amount ?? 0
              );
              if (element.document_file != null) {
                element.document_file?.forEach(
                  (subElement: any, subsubIndex: number) => {
                    formData.append(
                      `declaration_sub_document[${index}][${SubIndex}][${subsubIndex}]`,
                      subElement
                    );
                  }
                );
              }

              if (element.pre_sub_declaration_document != null) {
                element.pre_sub_declaration_document?.forEach(
                  (subElement: any, subsubIndex: number) => {
                    formData.append(
                      `pre_sub_declaration_document[${index}][${SubIndex}][${subsubIndex}]`,
                      subElement.file
                    );
                  }
                );
              }
            }
          );
        }
      } else if (key == 'rantal_house_documents') {
        for (let i in payload[key]) {
          formData.append(`rantal_house_documents[${i}]`, payload[key][i]);
        }
      } else if (key == 'other_income_document') {
        for (let i in payload[key]) {
          formData.append(`other_income_document[${i}]`, payload[key][i]);
        }
      } else {
        formData.append(key, payload[key]);
      }
    }

    return this.post(url, formData);
  }

  private handleError(error: any): Promise<any> {
    if (error.status === 0) {
      return Promise.reject<string>('There is no internet connection');
    }
    if (error.status) {
      if (error.status === 401) {
        localStorage.clear();
        window.location.href = '/';
        return Promise.reject<string>(error.statusText + '! No user found with your token');
      }
      if (error.status === 403) {
        localStorage.clear();
        window.location.href = '/';
        return Promise.reject<string>('Your are logged Out');
      }
      return Promise.reject<string>(error.statusText);
    }
    // if (
    //   (error.message) ||
    //   error == 'No user found with your token'
    // ) {
    //   localStorage.clear();
    //   window.location.href = '/';
    // }
    return Promise.reject<string>(error.message || error);
  }

  //ATS
  
  getAts(url:string, options:any){  
    console.log(options)
    return this.http.get<any>(`${this.BASE_ATS_URL}/${this.BASE_HREF_ATS}/${url}`, options)
  }
  simpleGetAts(url:string){
    return this.http.get<any>(`${this.BASE_ATS_URL}/${this.BASE_HREF_ATS}/${url}`)
  }

   postAts(url:string, payload:any){
    return this.http.post<any>(`${this.BASE_ATS_URL}/${this.BASE_HREF_ATS}/${url}`, payload)
    
    
  }

  postAtsFormData(url: string, payload: any) {
    var formData = new FormData();
    for (var key in payload) {
      formData.append(key, payload[key]);
    }
    // return this.http.post<any>(`${this.Base_Url}/${url}`, formData);
    return this.http.post<any>(`${this.BASE_ATS_URL}/${this.BASE_HREF_ATS}/${url}`, payload)
  }
  updateAts(url:string, payload:any){
    return this.http.put<any>(`${this.BASE_ATS_URL}/${this.BASE_HREF_ATS}/${url}`, payload)
  }

  //ATS END
}
