<div class="info-fluid height100">
    <div class="card info_box">
        <div class="p-15 d-flex flex-wrap align-items-center border-bottom">
            <h5 class="mb-0">{{translations?.requisitionsApprovalList}}</h5>
            <!-- <button class="btn button-solide ms-auto" (click)="routeToPage()" >+ NEW REQUISITION</button> -->
        </div>
        <div class="card-body p-0">
            <div class="p-15 d-flex">
                <div class="serch-main me-13">
                    <i class="bi bi-search"></i>
                    <!-- <input type="text" class="form-control" placeholder="Search by name, id..."> -->
                    <input type="text" class="form-control " placeholder={{translations?.searchByNameId}} [formControl]="searchkey">
                </div>
            </div>
            <div class="table_main">
                <table class="table table-bordered">
                    <tbody>
                        <tr>
                        <td style="text-align: center;">{{translations?.date}}</td>
                        <td style="text-align: center;">{{translations?.id}}</td>
                        <td style="text-align: center;">{{translations?.jobTitle}}</td>
                        <td style="text-align: center;">{{translations?.positions}}</td>
                        <td style="text-align: center;">{{translations?.department}}</td>
                        <td style="text-align: center;">{{translations?.experience}}</td>
                        <td style="text-align: center;">{{translations?.requestedBy}}</td>
                        <!-- <td style="text-align: center;">Application</td> -->
                        
                        <td style="text-align: center;">{{translations?.currentStage}}</td>
                        <!-- <td style="text-align: center;">Status</td> -->
                        <td style="text-align: center;">{{translations?.action}}</td>
                        </tr>
                        <ng-container *ngIf="this.requisitionApprovalList?.length !== 0">
                        <tr *ngFor="let data of requisitionApprovalList">                       
                        <td style="text-align: center;">{{ (data?.updatedAt ? data?.updatedAt : data?.createdAt) | date:'yyyy-MM-dd' }}</td>
                        <td style="text-align: center;">{{data?.c_id}}</td>
                        <td style="text-align: center;">{{data?.job_title}}</td>
                        <td style="text-align: center;">{{data?.position}}</td>
                        <td style="text-align: center;">{{data?.deptartment_name}}</td>
                        <td style="text-align: center;"> {{(data?.experience == "" ) ? "N/A" : data?.experience}}</td>
                        <td style="text-align: center;">{{data?.createdBy?.name}}</td>
                        <!-- <td>21</td> -->
                        
                        <td>{{data?.approval_status | capitalizeFirstLetter}}</td>
                        <!-- <td>{{data?.status}}</td>     -->

                        <td>
                            <div class="d-flex align-items-center justify-content-center">
                                <button class="btn button_action blue_action" (click)="jobDetails(data)"><i class="bi bi-eye-fill"></i></button>
                            </div>
                        </td>
                       
                       
                               
                        </tr>
                        </ng-container>
                    
                            <tr *ngIf="this.requisitionApprovalList?.length == 0">
                                <td [attr.colspan]="8" class="text-center">{{translations?.noDataFound}}</td>
                            </tr>
                    
                        
                    </tbody>
                </table>
            </div>
            <!-- <div class="mt-0" *ngIf="requisitionList?.length !== 0">
                <app-custom-pagination [rows]="requisitionList" [paginationOptions]="paginationOptions" (onPageClicked)="fetchRequistionList($event, null)"></app-custom-pagination>
            </div> -->
        </div>
    </div>
</div>