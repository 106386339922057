
    
        <div class="p-15 border-bottom modal-header bg-light">
            <div class="modal-text">
                <h3 class="derktext left-line mb-0 static-text">Interview Response Form</h3>
            </div>
            <button class="btn-close btn ms-4 ms-auto"><i class="bi bi-x-lg" (click)="closeDialog()"></i></button>
        </div>
   
        <form [formGroup]="interviewForm">
          
                <div class="p-15 pt-4 modal-body s">
                    <div class="risection">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="mb-3 form-group">
                                    <h2>{{this.data?.interviewData?.fname}}&nbsp; {{this.data?.interviewData?.lname}}</h2>
                                </div>
                                </div>
                            </div>

                            <div class="col-md-12">
                                <div class="mb-3 form-group">    
                                    <label for="" class="form-label">Select Qualify/Disqualify <span class="text-danger">*</span></label>
                                    <ngx-select-dropdown formControlName="qualify_status" [options]="qualifyStatusMaster" class="form-control" [config]="{search: true, placeholder: 'Select One', 'height': '200px'}"></ngx-select-dropdown>
                                    <div *ngIf="interviewForm.controls['qualify_status'].invalid && (interviewForm.controls['qualify_status'].dirty || interviewForm.controls['qualify_status'].touched)">
                                        <small class="text-danger">This Field Is Required.</small>
                                    </div>
                                </div>
                            </div>

                             <div class="col-md-12">
                                <div class="mb-3 form-group">
                                <label for="" class="form-label">Remarks <span class="text-danger">*</span></label>
                                 <textarea class="inputgray form-control" style="height: 100px;" formControlName="remarks" ></textarea>
                                 <div *ngIf="interviewForm.controls['remarks'].invalid && (interviewForm.controls['remarks'].dirty || interviewForm.controls['remarks'].touched)">
                                    <small class="text-danger">This Field Is Required.</small>
                                 </div>
                                 </div>
                                 
                            </div>
                          
                        </div>
                    </div>
                
                

                <div class="modal-footer p-15 py-2 border-top">
                    <button class="btn button-solide" (click)="selectApplicant($event)" ><span>Submit</span></button>
                    <!-- <button class="btn button-solide gray-btn" (click)="selectApplicant($event)" >Reject</button> -->
                </div>
          
        </form>
