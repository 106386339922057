import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent {
  lang: any = 'en';
requistionId:any;
activeTab!: string;
constructor(
  private router: Router
){

}

ngOnInit(): void {
  let r = this.router.url.split('/');
  console.log(r)
  this.requistionId = r[5]
}

routerChange(routeString: string) {
  this.activeTab = routeString;
  this.router.navigate([this.lang, 'ats','requistion', routeString, this.requistionId]);
  console.log(routeString)
}

isActive(routeString: string): boolean {
  if (this.router.url.includes(routeString)) {
    return true;
  }
  return false;
}

}
