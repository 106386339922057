import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Editor, toHTML } from 'ngx-editor';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { debounceTime } from 'rxjs';
import { AtsService } from 'src/app/services/ats.service';
import * as Global from 'src/app/shared/globals';

@Component({
  selector: 'app-create-requistion',
  templateUrl: './create-requistion.component.html',
  styleUrls: ['./create-requistion.component.css']
})
export class CreateRequistionComponent {
  slidesStore:any = ['assets/image1.jpg', 'assets/image2.jpg', 'assets/image3.jpg'];
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    margin:20,
    navSpeed: 600,
    navText: ['&#8592', '&#8594;'],
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      760: {
        items: 1.5,
      },
      1000: {
        items: 1.5,
      },
    },
    
  };
  panelOpenState: boolean = false;
  departmentsMaster!: any[];
  requistionForm!:FormGroup;
  companyData!:any;
  companyBranchMaster!:any[];
  Global = Global;
  editor!: Editor;
  templateList!:any[];
  selectedTemplateId!: string;
  lang = 'en';
  requistionId:any;
  operation:any;
  requistionData!:any;
  currentDate!:string;
  singleTemplateData:any;
  shiftMaster!:any[];
  jobTypeMaster!:any[];
  workModeMaster!:any[];
  workMode!:any;
  jobType!:any;
  shift!:any;
  approver_status:any;
  requistion_details:any[] = [];
  department!:any;
  branch!:any;

  constructor(
    private spinner: NgxSpinnerService,
    private atsService: AtsService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
  ){
    // this.initialize();
   

 }

  // async initialize() {
  //   await this.fetchEmployeeDetails(); // Wait for the method to finish
  //   this.initForm(); // Initialize the form after employee details are fetched
  // }

  initForm(){
    const date = new Date();
    this.currentDate = this.formatDate(date);
    console.log(this.currentDate);
    console.log(this.companyData)
     this.requistionForm = this.formBuilder.group({
      date: [this.currentDate, Validators.compose([Validators.required])],
      company_name: [this.companyData?.company?.establishment_name, Validators.compose([Validators.required])],
      department: [null, Validators.compose([])],
      branch_name: [null, Validators.compose([])],
      job_title: [null, Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z ]+$')])],
      position: [null, Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z ]+$')])],
      job_description: [null, Validators.compose([])],
      experience: [null, Validators.compose([ Validators.pattern('^\\d+(-\\d+)?$')])],
      status: ['active', Validators.compose([])],
      template_id: [''],
      close_date: [null, Validators.compose([])],
      salary: [null, Validators.compose([Validators.pattern('^\\d+(-\\d+)?$')])],
      work_mode: [null, Validators.compose([])],
      job_type: [null, Validators.compose([])],
      shift: [null, Validators.compose([])],
      job_location: [null, Validators.compose([])],
      vacancy: [null, Validators.compose([ Validators.pattern('^[0-9]+$')])]
      
  
      
  });

  this.shiftMaster = [
    { 'value': 'day', 'description': 'Day' },
    { 'value': 'night', 'description': 'Night' },
  ];

  this.jobTypeMaster = [
    { 'value': 'permanent', 'description': 'Permanent' },
    { 'value': 'temporary', 'description': 'Temporary' },
  ];

  this.workModeMaster = [
    { 'value': 'hybrid', 'description': 'Hybrid' },
    { 'value': 'wfh', 'description': 'Work From Home' },
    { 'value': 'wfo', 'description': 'Work From Office' }
  ];
  }

 async ngOnInit(): Promise<void> {
  await this.fetchEmployeeDetails(); // Wait for the method to finish
  this.initForm();
    this.editor = new Editor();
    // console.log(this.companyBranchMaster)
    this.route.params.subscribe(params => {
      console.log(params)
      this.requistionId = params['id'] ?? null;
      console.log(this.requistionId)
    })

   

   this.requistionForm.get('department')?.valueChanges.pipe(debounceTime(500)).subscribe(value => {
    console.log(value)
      const depId = value?.id;
      if(depId){
        this.fetchTemplate(depId);
      }
      
    });
   

    if(!this.requistionId){
      this.operation = 'add';
      
   await  this.fetchMaster();
   
   this.requistionForm.get('job_description')?.valueChanges
   .pipe(debounceTime(500))
   .subscribe(value => {
    console.log(value)
     if (value && typeof value === 'object') {
       const html = toHTML(value);
       console.log(html)
       this.requistionForm.patchValue({
         job_description: html,
       }, { emitEvent: false }); // prevent recursive valueChanges triggering
     }
   });
      
    }else{
      this.fetchMaster();
      let r = this.router.url.split('/')
      if(r[4] == 'view'){
        this.operation = 'view';
        if (r[4] === 'view') {
         
         await this.fetchMaster();
          this.fetchRequistionDetails();
        }
      
       
      }else if (r[4]== 'edit') {
        this.operation = 'edit';
        
          const control: any = this.requistionForm.get('department');
          control.disable();
          if (control) {
              control.clearValidators(); // Clear all validators
              control.updateValueAndValidity();
          }
      ;
        await this.fetchMaster();
      await  this.fetchRequistionDetails();
      this.requistionForm.get('job_description')?.valueChanges
   .pipe(debounceTime(500))
   .subscribe(value => {
    console.log(value)
     if (value && typeof value === 'object') {
       const html = toHTML(value);
       console.log(html)
       this.requistionForm.patchValue({
         job_description: html,
       }, { emitEvent: false }); 
     }
   });
        
       
      }
      
    }
  
  
  }

  fetchEmployeeDetails() {
    this.spinner.show();
    return new Promise((resolve, reject) => {
      this.atsService.fetchAccountDetails({}).then((res: any) => {
        if (res?.status == 'success') {
          this.companyData = res?.employee_data ?? {};
          console.log(this.companyData)
          this.companyBranchMaster = [];
          if (this.companyData?.company_branch && Array.isArray(this.companyData?.company_branch)) {
            this.companyData?.company_branch.forEach((element: any) => {
                  this.companyBranchMaster.push({ id: element._id, description:`${element.branch_name}` })
              });
          }
          this.spinner.hide();
          resolve(true);
        } else {
          resolve(false);
        }
      }).catch((err: any) => {
        reject(err);
      })
    });
  }

  getWorkMode(event: any) {
    console.log(event)
    this.requistionForm.patchValue({
      work_mode: event?.value?.description,
    })
    this.workMode = event?.value?.value;
  }

  getJobType(event: any) {
    this.requistionForm.patchValue({
      job_type: event?.value?.description,
    })
    this.jobType = event?.value?.value;
  }

  getShift(event: any) {
    this.requistionForm.patchValue({
      shift: event?.value?.description,
    })
    this.shift = event?.value?.value;
  }

  // getDepartment(event:any){
  //   console.log(event)
  //   this.requistionForm.patchValue({
  //     department: event?.value?.description
  //   })
  //    this.department = event?.value?.id
  //    console.log(this.department)
  // }

  getBranch(event:any){
    console.log(event)
    this.requistionForm.patchValue({
      branch_name: event?.value?.description ?? ""
    })
     this.branch = event?.value?.description ?? ""
     console.log(this.branch)
  }

  formatDate(date: Date): string {
    const day = String(date.getDate()).padStart(2, '0'); 
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const year = date.getFullYear();
  
    return `${year}-${month}-${day}`; 
  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }

  onTemplateSelect(id: string) {
    this.selectedTemplateId = id; // Update selectedTemplateId variable
    console.log('Selected Template ID:', id);
  }

 

  fetchMaster(){
    return new Promise<any>((resolve, reject) => {
       this.spinner.show();
      this.atsService.getMasterList().subscribe((res: any) => {
        console.log(res, "master");
        if (res?.status == 200) {
          this.departmentsMaster = [];
          if (res?.data?.departments && Array.isArray(res?.data?.departments)) {
            res?.data?.departments.forEach((element: any) => {
                  this.departmentsMaster.push({ id: element.id, description: `${element.description}`, master_id: element?.master_id, name:`${element.name}` })
              });
          }
         
          resolve(true);
          this.spinner.hide();
        } else {
          this.spinner.hide();
          this.toastr.error(res?.message);
          resolve(false);
        }
      })
    });
  }

  fetchTemplate(deptId:any) {
    return new Promise((resolve, reject) => {
      this.atsService.getDepartmentTemplate(deptId).subscribe((res: any) => {
         console.log(res)
        const data = res?.data ?? {};
        // console.log(data)
        if (res) {
          this.templateList = data?.listData ?? [];
          console.log(this.templateList.length)
        
          this.spinner.hide();
         
          resolve(true);
        } else {
          this.toastr.error(res?.message);
          resolve(false);
        }
      })
    })
  }

  fetchTempDetails(){
    return new Promise<any>((resolve, reject) => {
       this.spinner.show();
      this.atsService.getTempDetails(this.requistionData?.template_id).subscribe((res: any) => {
        console.log(res, "tttt");
        if (res?.status == 200) {
          this.singleTemplateData = res?.data?.listData ?? {};
          console.log(this.singleTemplateData)
          
          resolve(true);
          this.spinner.hide();
        } else {
          this.spinner.hide();
          this.toastr.error(res?.message);
          resolve(false);
        }
      })
    });
  }

  fetchRequistionDetails(){
    return new Promise<any>((resolve, reject) => {
       this.spinner.show();
      this.atsService.getSingleRequistion(this.requistionId).subscribe((res: any) => {
        // console.log(res, "tttt");
        if (res?.message == 'Get single requistion data') {
          this.requistionData = res?.data?.listData ?? {};
          console.log(this.requistionData)
             this.requistion_details = this.requistionData ?? {}
          this.requistionForm.patchValue({
            
            "company_name": this.requistionData?.company_name ?? "",
            "branch_name":this.companyBranchMaster.find((obj:any) => {
              // console.log(obj)
              if(obj?.description === this.requistionData?.branch_name){
                return this.branch = obj.description ?? ''
              }
             }),

            "job_title": this.requistionData?.job_title ?? "",
            "position": this.requistionData?.position ?? "",
            "job_description": this.requistionData?.job_description ?? "",
            "department": this.departmentsMaster.find((obj:any) => {
              // console.log(obj)
              if(obj?.id === this.requistionData?.department_id){
                return this.department = obj?.id ?? ''
              }
             }),
            "experience": this.requistionData?.experience ?? "",
            "template_id": this.requistionData?.template_id ?? "",
            "status": "active",

            "date": this.datePipe.transform(
              this.requistionData?.date,
              'yyyy-MM-dd' 
            ),
            "close_date": this.datePipe.transform(
              this.requistionData?.close_date,
              'yyyy-MM-dd' 
            ),
          "salary": this.requistionData?.salary ?? "",
          "work_mode": this.workModeMaster.find((obj:any)=>{
            if(obj.value === this.requistionData?.work_mode){
              return this.workMode = obj.value ?? ""
            }
          }),
          "job_type": this.jobTypeMaster.find((obj:any)=>{
            if(obj.value === this.requistionData?.job_type){
              return this.jobType = obj.value ?? ""
            }
          }),
          "shift": this.shiftMaster.find((obj:any)=>{
            if(obj.value === this.requistionData?.shift){
              return this.shift = obj.value ?? ""
            }
          }),
          "job_location": this.requistionData?.job_location ?? "",
          "vacancy": this.requistionData?.vacancy ?? ""

          })
        
          
          this.fetchTempDetails();
          
          resolve(true);
          this.spinner.hide();
        } else {
          this.spinner.hide();
          this.toastr.error(res?.message);
          resolve(false);
        }
      })
    });
  }


  submit(event: any) {
    Object.keys(this.requistionForm.controls).forEach(field => {
      const control = this.requistionForm.get(field);
      // Check if control is invalid and has been touched
      if (control?.invalid && control?.touched) {
      
        console.log('Invalid control:', field);
        
      }
    });
     this.requistionForm.markAllAsTouched();
    setTimeout(function () {
        Global.scrollToQuery("p.error-element");
    }, 100)
      console.log('create');
      
    if (this.requistionForm.valid) {
     
        const button = event.currentTarget as HTMLButtonElement;
        button.classList.add('btn-loading');

        this.atsService.addRequistion({
          "company_name": this.requistionForm.value.company_name ?? "",
          "branch_name": this.branch ?? "",
          "job_title": this.requistionForm.value.job_title ?? "",
          "position": this.requistionForm.value.position ?? "",
          "job_description": this.requistionForm.value.job_description ?? "",
          "department": this.requistionForm.value.department.id ?? "",
          "experience": this.requistionForm.value.experience ?? "",
          "template_id":this.selectedTemplateId,
          "status": "active",
          "date": this.requistionForm.value.date ?? "",

          "close_date": this.requistionForm.value.close_date ?? "",
          "salary": this.requistionForm.value.salary ?? "",

          "work_mode": this.workMode ?? "",
          "job_type": this.jobType ?? "",
          "shift": this.shift ?? "",
          "job_location": this.requistionForm.value.job_location ?? "",
          "vacancy": this.requistionForm.value.vacancy ?? ""
        }).subscribe({next:(res) => {
          
            if (res.status ==  201) {
            this.toastr.success("Template Created Successfully");
            this.routeToPage();
               
          }  else if (res.status == 'val_err') {
            this.toastr.error(Global.showValidationMessage(res.val_msg));
        } else {
            this.toastr.error(res.message);
        }
          button.classList.remove('btn-loading')
        },
        error: (err) => {
          console.error('Error occurred:', err); // Handle the error response
           button.classList.remove('btn-loading');
       },
       complete: () => {
        console.log('Request completed'); // Optional: Handle when the observable completes
     }
    })

  }
    }

    update(event: any) {
      Object.keys(this.requistionForm.controls).forEach(field => {
        const control = this.requistionForm.get(field);
        // Check if control is invalid and has been touched
        if (control?.invalid && control?.touched) {
        
          console.log('Invalid control:', field);
          
        }
      });
       this.requistionForm.markAllAsTouched();
      setTimeout(function () {
          Global.scrollToQuery("p.error-element");
      }, 100)
        console.log('create');
        
      if (this.requistionForm.valid) {
       
          const button = event.currentTarget as HTMLButtonElement;
          button.classList.add('btn-loading');
  
          this.atsService.updateRequistion({
          "id": this.requistionData?.id ?? "",
          "c_id": this.requistionData?.c_id ?? "",
          "company_name": this.requistionForm.value.company_name ?? "",
          "branch_name": this.branch ?? "",
          "job_title": this.requistionForm.value.job_title ?? "",
          "position": this.requistionForm.value.position ?? "",
          "job_description": this.requistionForm.value.job_description ?? "",
          "department": this.department ?? "",
          "experience": this.requistionForm.value.experience ?? "",
          "template_id":this.selectedTemplateId,
          "status": "active",
          "date": this.requistionForm.value.date ?? "",
          "close_date": this.requistionForm.value.close_date ?? "",
          "salary": this.requistionForm.value.salary ?? "",
          // "work_mode": this.requistionForm.value.work_mode ?? "",
          // "job_type": this.requistionForm.value.job_type ?? "",
          // "shift": this.requistionForm.value.shift ?? "",
          "work_mode": this.workMode ?? "",
          "job_type": this.jobType ?? "",
          "shift": this.shift ?? "",
          "job_location": this.requistionForm.value.job_location ?? "",
          "vacancy": this.requistionForm.value.vacancy ?? ""
          }).subscribe({next:(res) => {
            
              if (res.status ==  200) {
              this.toastr.success("Template Created Successfully");
              this.routeToPage();
                 
            }  else if (res.status == 'val_err') {
              this.toastr.error(Global.showValidationMessage(res.val_msg));
          } else {
              this.toastr.error(res.message);
          }
            button.classList.remove('btn-loading')
          },
          error: (err) => {
            console.error('Error occurred:', err); // Handle the error response
             button.classList.remove('btn-loading');
         },
         complete: () => {
          console.log('Request completed'); // Optional: Handle when the observable completes
       }
      })
  
    }
      }
    
    routeToPage(){
      this.router.navigate([this.lang, 'ats', 'requistion-list'])
    }

}
