import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import PaginationOptions from 'src/app/model/PaginationOptions';
import { AtsService } from 'src/app/services/ats.service';
import * as Global from 'src/app/shared/globals';
import { ShortlistDialogComponent } from './shortlist-dialog/shortlist-dialog.component';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-shortlist',
  templateUrl: './shortlist.component.html',
  styleUrls: ['./shortlist.component.css']
})
export class ShortlistComponent {
  requistionId:any;
  shortlistedList:any[] = [];
  paginationOptions: PaginationOptions = Global.resetPaginationOption();
  constructor(
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private atsService: AtsService,
    private toastr: ToastrService,
    private dialogRef: MatDialog,
    private router: Router
  ){

  }

  ngOnInit(): void {
    this.spinner.show();
    this.route.params.subscribe(params => {
      console.log(params)
      this.requistionId = params['id'] ?? null;
      console.log(this.requistionId)
  })
  this.fetchApplicantReviewList(this.paginationOptions?.page)

}

fetchApplicantReviewList(page: number) {
  return new Promise((resolve, reject) => {
    this.atsService.shortListedApplicant({
      params: new HttpParams()
      .set('page', page?.toString() || '1')  // dynamic page
      .set('limit', '10')
      
    }, this.requistionId).subscribe((res: any) => {
       console.log(res)
      const data = res?.data ?? {};
       console.log(data)
      if (res?.status == 200) {
         this.shortlistedList = data?.listData ?? [];
         console.log(this.shortlistedList)
        
  
        this.spinner.hide();
        this.paginationOptions = {
          page: data?.page,
          limit: data?.limit,
          hasNextPage: data?.hasNextPage,
          hasPrevPage: data?.hasPrevPage,
          nextPage: data?.nextPage,
          prevPage: data?.prevPage,
          totalDocs: data?.totalItems,
          totalPages: data?.totalPages,
          pagingCounter: data?.pagingCounter
        }
        resolve(true);
      } else {
        this.toastr.error(res?.message);
        resolve(false);
      }
    })
  })
}

openDialog(applicantId:any) {
  const dialogRef = this.dialogRef.open(ShortlistDialogComponent, {
    width: '1500px',
    disableClose: true,
    autoFocus: false,
    data: {requistion_id: this.requistionId, applicant_id:applicantId }
  });

  dialogRef.afterClosed().subscribe(result => {
    // this.fetch();
  });
}

downloadCvFile(filepath:any){
  console.log(filepath)
  const fileName = 'Resume'
  this.atsService.downloadFileDirectly(filepath, 'Resume.pdf');
}

backToApprovalList(){
  console.log("kjhfkj")
  this.router.navigate(['en', 'ats', 'approval-list' ])
}
}
