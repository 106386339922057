import { HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, Subscription } from 'rxjs';
import { AtsService } from 'src/app/services/ats.service';
import { LanguageTranslateService } from 'src/app/services/language-translate.service';
import * as Global from 'src/app/shared/globals';

@Component({
  selector: 'app-approval-list',
  templateUrl: './approval-list.component.html',
  styleUrls: ['./approval-list.component.css']
})
export class ApprovalListComponent {
  requisitionApprovalList!:any[];
  // paginationOptions: PositionOptions = Global.resetPaginationOption();
  searchkey = new FormControl('');
  requistionId:any;
  resData:any;
  translations: any = [];
  currentLanguage$?: Subscription;
  languages: any[] = [];
  lang: string;

 
  constructor(
    private atsService: AtsService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private langService: LanguageTranslateService,
    private route: ActivatedRoute,
   
  ){
  
  }
  
  ngOnInit(): void {
    this.spinner.show();
    this.fetchApprovalList(this.searchkey.value);
    this.searchkey.valueChanges.pipe(debounceTime(700)).subscribe(value => {
      this.fetchApprovalList(value);
    });
    this.currentLanguage$ = this.langService.currentLanguage$.subscribe(async () => {
      return new Promise<boolean>(async (resolve, reject) => {
        await this.langService.loadTranslations('ats', 'ApprovalListComponent')?.then((res: any) => {
          this.translations = res;
          resolve(true);
        }).catch((err: any) => {
          console.error(`Failed to load translations`, err);
          reject(false);
        });
      })
    });
  }
  
  fetchApprovalList( searchkey: any) {
    return new Promise((resolve, reject) => {
      this.atsService.getRequistionApprovalList(
        {
          params: new HttpParams()
          
          .set('filter', 'active')
          .set('searchkey', searchkey || '')
        }
      ).subscribe((res: any) => {
       
        console.log(res)
        const data = res?.data ?? {};
       
        if (res?.status == 200) {
           this.requisitionApprovalList = data?.listData ?? [];
          
          
          
          this.spinner.hide();
         
          resolve(true);
        } else {
          this.toastr.error(res?.message);
          resolve(false);
        }
      })
    })
  }

  approve(data:any){
    console.log(data)
    return new Promise((resolve, reject) => {
      this.atsService.approveRequistion({
    "requisition_id": data?.id,
    "remarks": " ",
    "status": "approved",
    "company_name": data?.company_name ?? "",
    "branch_name": data?.branch_name ?? "",
    "job_description": data?.job_description ?? "",
    "department_id": data?.department_id ?? "",
    "experience": data?.experience ?? "",
    "close_date": data?.close_date ?? "",
    "salary": data?.salary ?? "",
    "work_mode": data?.work_mode ?? "",
    "job_type": data?.job_type ?? "",
    "shift": data?.shift ?? "",
    "job_location": data?.job_location ?? "",
    "vacancy": data?.vacancy ?? ""
      }).subscribe((res: any) => {
       
        console.log(res)
       
        if (res?.status == 200) {
          this.toastr.show(res?.message);
          this.fetchApprovalList(this.searchkey.value);
          resolve(true);
        } else {
          this.toastr.error(res?.message);
          resolve(false);
        }
      })
    })
  }

  reject(data:any){
    console.log(data)
    return new Promise((resolve, reject) => {
      this.atsService.approveRequistion({
    "requisition_id": data?.id,
    "remarks": " ",
    "status": "rejected",
    "company_name": data?.company_name ?? "",
    "branch_name": data?.branch_name ?? "",
    "job_description": data?.job_description ?? "",
    "department_id": data?.department_id ?? "",
    "experience": data?.experience ?? "",
    "close_date": data?.close_date ?? "",
    "salary": data?.salary ?? "",
    "work_mode": data?.work_mode ?? "",
    "job_type": data?.job_type ?? "",
    "shift": data?.shift ?? "",
    "job_location": data?.job_location ?? "",
    "vacancy": data?.vacancy ?? ""
      }).subscribe((res: any) => {
       
        console.log(res)
       
        if (res?.status == 200) {
          this.toastr.show(res?.message)
          resolve(true);
        } else {
          this.toastr.error(res?.message);
          resolve(false);
        }
      })
    })
  }


  jobDetails(tempData:any){
    console.log(tempData)
    const id = tempData?.id;
    this.router.navigate([this.lang, 'ats', 'requistion', 'job-details', id ])
      
   }
    
  
   
  
    
  
    

}
