import { HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import PaginationOptions from 'src/app/model/PaginationOptions';
import { AtsService } from 'src/app/services/ats.service';
import * as Global from 'src/app/shared/globals';
import { InterviewStatusDialogComponent } from './interview-status-dialog/interview-status-dialog.component';
import { HiringDialogComponent } from './hiring-dialog/hiring-dialog.component';
import { InterviewDialogComponent } from './interview-dialog/interview-dialog.component';

@Component({
  selector: 'app-interview',
  templateUrl: './interview.component.html',
  styleUrls: ['./interview.component.css']
})
export class InterviewComponent {
  requistionId:any;
  intervieweeList:any[] = [];
  paginationOptions: PaginationOptions = Global.resetPaginationOption();
  qualify_status:any;
  constructor(
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private atsService: AtsService,
    private toastr: ToastrService,
    private dialogRef: MatDialog,
    private router: Router
    
  ){

  }

 async ngOnInit(): Promise<void> {
    this.spinner.show();
    this.route.params.subscribe(params => {
      console.log(params)
      this.requistionId = params['id'] ?? null;
      console.log(this.requistionId)
  })
 await this.fetchApplicantInterviewList(this.paginationOptions?.page);
  this.atsService.fetchAccountDetails({}).then((res:any)=>{
    console.log(res)
   console.log(this.intervieweeList)
    this.intervieweeList.map((obj:any)=>{
      console.log(obj)
      obj?.interviews.map((result:any)=>{
        if(result?.interviewerDetails?.master_id === res?.employee_data?._id){
          console.log(result?.qualify_status)
          return this.qualify_status = result?.qualify_status;
        }
      })
      
    })
  })

}

fetchApplicantInterviewList(page: number) {
  return new Promise((resolve, reject) => {
    this.atsService.applicantForInterview({
      params: new HttpParams()
      .set('page', page?.toString() || '1')  // dynamic page
      .set('limit', '10')
      
    }, this.requistionId).subscribe((res: any) => {
       console.log(res)
      const data = res?.data ?? {};
       console.log(data)
      if (res?.message == "List interviews") {
         this.intervieweeList = data ?? [];
         console.log(this.intervieweeList)
        
  
        this.spinner.hide();
        this.paginationOptions = {
          page: data?.page,
          limit: data?.limit,
          hasNextPage: data?.hasNextPage,
          hasPrevPage: data?.hasPrevPage,
          nextPage: data?.nextPage,
          prevPage: data?.prevPage,
          totalDocs: data?.totalItems,
          totalPages: data?.totalPages,
          pagingCounter: data?.pagingCounter
        }
        resolve(true);
      } else {
        this.toastr.error(res?.message);
        resolve(false);
      }
    })
  })
}

openDialog(data:any) {
  console.log(data)
  const dialogRef = this.dialogRef.open(InterviewStatusDialogComponent, {
    width: '1200px',
    disableClose: true,
    autoFocus: false,
    data: {interviewData: data }
  });

  dialogRef.afterClosed().subscribe((result:boolean) => {
    if(result == true){
     
    }
  });
}

openHiringDialog(data:any) {
  const dialogRef = this.dialogRef.open(HiringDialogComponent, {
    width: '500px',
    disableClose: true,
    autoFocus: false,
    data: {applicantData: data }
  });

  dialogRef.afterClosed().subscribe((result:boolean) => {
    if(result == true){
     
    }
  });
}

backToApprovalList(){
  console.log("kjhfkj")
  this.router.navigate(['en', 'ats', 'approval-list' ])
}

interviewDialog(data:any) {
  console.log(data)
  const dialogRef = this.dialogRef.open(InterviewDialogComponent, {
    width: '500px',
    disableClose: true,
    autoFocus: false,
    data: {interviewData: data }
  });

  dialogRef.afterClosed().subscribe((result:boolean) => {
    if(result == true){
     
    }
  });
}

}
