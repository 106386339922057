import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AtsComponent } from './ats.component';
import { ApprovalListComponent } from './approval-list/approval-list.component';
import { RequisitionComponent } from './requisition/requisition.component';
import { CreateRequistionComponent } from './requisition/create-requistion/create-requistion.component';
import { ViewJobDetailsComponent } from './job-details/view-job-details/view-job-details.component';
import { ApplicantsComponent } from './job-details/applicants/applicants.component';
import { ReviewComponent } from './job-details/review/review.component';
import { ShortlistComponent } from './job-details/shortlist/shortlist.component';
import { InterviewComponent } from './job-details/interview/interview.component';
import { HiredComponent } from './job-details/hired/hired.component';
import { RejectedComponent } from './job-details/rejected/rejected.component';

const routes: Routes = [
  {
    path: "", component: AtsComponent, children:[
  { path: "", redirectTo: "approval-list", pathMatch: "full"},
  {path:"approval-list", component:ApprovalListComponent},
  {path:"requistion-list", component:RequisitionComponent},
  {path:"requistion/create", component:CreateRequistionComponent},
  { path: 'requistion/edit/:id', component:CreateRequistionComponent},

  { path: 'requistion/job-details/:id', component: ViewJobDetailsComponent, data: { pageEngTitle: 'Job Details' } },
  { path: 'requistion/applicants/:id', component: ApplicantsComponent, data: { pageEngTitle: 'Applicants' } },
  { path: 'requistion/review/:id', component: ReviewComponent, data: { pageEngTitle: 'Review' } },
  { path: 'requistion/shortlist/:id', component: ShortlistComponent, data: { pageEngTitle: 'Shortlisted' } },
  { path: 'requistion/interview/:id', component: InterviewComponent, data: { pageEngTitle: 'Intrerview' } },
  { path: 'requistion/hired/:id', component: HiredComponent, data: { pageEngTitle: 'Hired' } },
  { path: 'requistion/rejected/:id', component: RejectedComponent, data: { pageEngTitle: 'Rejected' } },
    ]
  }


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AtsRoutingModule { }
