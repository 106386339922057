import { HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import PaginationOptions from 'src/app/model/PaginationOptions';
import { AtsService } from 'src/app/services/ats.service';
import * as Global from 'src/app/shared/globals';

@Component({
  selector: 'app-applicants',
  templateUrl: './applicants.component.html',
  styleUrls: ['./applicants.component.css']
})
export class ApplicantsComponent {
  activeTab = 'applicant';
requistionId:any;
applicantList:any[] = [];
paginationOptions: PaginationOptions = Global.resetPaginationOption();
rowCheckedAll: Boolean = false;
checkedRowIds: any[] = [];
uncheckedRowIds: any[] = [];
allCheckedRowIds:any[] =[];
lang:any = 'en';
constructor(
  private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private atsService: AtsService,
    private toastr: ToastrService,
    private router: Router
){
  console.log(this.activeTab)
}

ngOnInit(): void {
  this.route.params.subscribe(params => {
    console.log(params)
    this.requistionId = params['id'] ?? null;
    console.log(this.requistionId)
  
})
this.spinner.show();
this.fetchApplicantList(this.paginationOptions.page)
}

anyRowsChecked(): boolean {
  return (
    this.rowCheckedAll == true ||
    this.checkedRowIds.length > 0 ||
    this.uncheckedRowIds.length > 0
  );
}

allRowsCheckboxChecked(event: any) {
  if (this.rowCheckedAll) {
    this.uncheckedRowIds.length = 0;
    this.rowCheckedAll = false;
    this.allCheckedRowIds = []
  } else {
    this.checkedRowIds.length = 0;
    this.rowCheckedAll = true;
    this.applicantList.forEach((element:any)=>{
      if(element?.approval_status == 'pending'){
      this.allCheckedRowIds.push(element.id)
      }
    })
    // this.allCheckedRowIds
  }
  this.fetchApplicantList(this.paginationOptions.page)
  
}

showCheckbox() {
  return this.applicantList.some((element: any) => element?.approval_status === 'pending');
}

rowCheckBoxChecked(event: any, row: any) {
  let rowId: any = row.id;
  let checkbox: any = document.querySelectorAll(
    '[data-checkbox-id="' + rowId + '"]'
  );
    
  if (checkbox.length > 0) {
    if (checkbox[0].checked) {
      this.uncheckedRowIds.splice(this.uncheckedRowIds.indexOf(rowId), 1);
      if (!this.rowCheckedAll) {
        if (!this.checkedRowIds.includes(rowId)) {
          this.checkedRowIds.push(rowId);
        }
      }
    } else {
      this.checkedRowIds.splice(this.checkedRowIds.indexOf(rowId), 1);
      if (this.rowCheckedAll) {
        if (!this.uncheckedRowIds.includes(rowId)) {
          this.uncheckedRowIds.push(rowId);
        }
      }
    }
  }
}

private isRowChecked(rowId: any) {
  if (!this.rowCheckedAll)
    return this.checkedRowIds.indexOf(rowId) >= 0 ? true : false;
  else return this.uncheckedRowIds.indexOf(rowId) >= 0 ? false : true;
}

fetchApplicantList(page: number) {
  return new Promise((resolve, reject) => {
    this.atsService.applicantList({
      params: new HttpParams()
      .set('page', page?.toString() || '1')  // dynamic page
      .set('limit', '10')
      
    }, this.requistionId).subscribe((res: any) => {
       console.log(res)
      const data = res?.data ?? {};
      // console.log(data)
      if (res?.status == 200) {
         this.applicantList = data?.listData ?? [];
         console.log(this.applicantList)
         this.applicantList.forEach((element: any,index:any) => {
          element.checked = this.isRowChecked(element._id);
  
        });
        // this.showCheckbox();
  
        this.spinner.hide();
        this.paginationOptions = {
          page: data?.page,
          limit: data?.limit,
          hasNextPage: data?.hasNextPage,
          hasPrevPage: data?.hasPrevPage,
          nextPage: data?.nextPage,
          prevPage: data?.prevPage,
          totalDocs: data?.totalItems,
          totalPages: data?.totalPages,
          pagingCounter: data?.pagingCounter
        }
        resolve(true);
      } else {
        this.toastr.error(res?.message);
        resolve(false);
      }
    })
  })
}

submitToReview(ev:any) {
  console.log(this.checkedRowIds)
  return new Promise((resolve, reject) => {
    this.atsService.sendForReview({
      applicantIds:this.checkedRowIds.length > 0 ? this.checkedRowIds : this.allCheckedRowIds
    }).subscribe((res: any) => {
     
       if (res?.status == 200) {
        this.spinner.show();
        this.fetchApplicantList(this.paginationOptions?.page);
        this.spinner.hide();
        this.toastr.success('Reviewed Successfully')
       
        resolve(true);
      } else {
        this.toastr.error(res?.message);
        resolve(false);
      }
    })
  })
}

downloadCvFile(filepath:any){
  console.log(filepath)
  const fileName = 'Resume'
  this.atsService.downloadFileDirectly(filepath, 'Resume.pdf');
}

backToApprovalList(){
  console.log("kjhfkj")
  this.router.navigate(['en', 'ats', 'approval-list' ])
}

}
