
<div class="info-fluid height100" *ngIf=" this.operation == 'add'">
    <div class="card info_box">
      <div class="p-15 d-flex flex-wrap align-items-center">
        <div class="headstu">
           <h5 class="mb-0 me-4">Requistion Form</h5>
          <!-- <span class="hedtag">Interview In-Progress</span>
          <button class="btn button_action  ms-4"><i class="bi bi-pencil-fill"></i></button>  -->
        </div>        
        <!-- <button class="btn button-solide border-btn ms-auto"><i class="bi bi-check"></i> Synched with <i class="bi bi-linkedin"></i></button> -->
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <form [formGroup]="requistionForm">
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group position-relative labelwarp">
                    <label for="">Department</label>
                    <ngx-select-dropdown style="background-color: #ffffff;" formControlName="department"  
                    [options]="departmentsMaster" class="form-control p-0 border-0"[config]="{ search: true, placeholder: 'Select One' }">
                   </ngx-select-dropdown>
                   <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'department', 'required')">This Field is Required</p>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group position-relative labelwarp">
                    <label for="">Date</label>
                    <input type="date" placeholder="" class="form-control" formControlName="date">
                    <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'date', 'required')">This Field is Required</p>
                  </div>
                </div>
              
                <div class="col-lg-6">
                  <div class="form-group position-relative labelwarp">
                    <label for="">Company Name</label>
                    <input type="text" placeholder="" class="form-control" formControlName="company_name">
                    <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'company_name', 'required')">This Field is Required</p>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group position-relative labelwarp">
                    <label for="">Branch Name</label>
                    <ngx-select-dropdown style="background-color: #ffffff;" formControlName="branch_name" (change)="getBranch($event)"
                    [options]="companyBranchMaster" class="form-control p-0 border-0"[config]="{ search: true, placeholder: 'Select One' }">
                   </ngx-select-dropdown>
                   <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'branch_name', 'required')">This Field is Required</p>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group position-relative labelwarp">
                    <label for="">Job Title</label>
                    <input type="text" placeholder="" class="form-control" formControlName="job_title">
                    <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'job_title', 'required')">This Field is Required</p>
                    <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'job_title', 'pattern')">Enter Valid Input.</p>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group position-relative labelwarp">
                    <label for="">Positions</label>
                    <input type="text" placeholder="" class="form-control" formControlName="position">
                    <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'position', 'required')">This Field is Required</p>
                    <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'position', 'pattern')">Enter Valid Input.</p>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group position-relative labelwarp">
                    <label for="">Experience</label>
                    <input type="text" placeholder="" class="form-control" formControlName="experience">
                    <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'experience', 'required')">This Field is Required</p>
                    <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'experience', 'pattern')">Enter Valid Input.</p>
                    <span class="unit">Years</span>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group position-relative labelwarp">
                    <label for="">Salary</label>
                    <input type="text" placeholder="" class="form-control" formControlName="salary">
                    <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'salary', 'required')">This Field is Required.</p>
                    <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'salary', 'pattern')">Enter Valid Input.</p>
                    <span class="unit">AED</span>
                  </div>
                </div>
  
                <div class="col-lg-6">
                  <div class="form-group position-relative labelwarp">
                    <label for="">No. Of Vacancy</label>
                    <input type="text" placeholder="" class="form-control" formControlName="vacancy">
                    <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'vacancy', 'required')">This Field is Required.</p>
                    <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'vacancy', 'pattern')">Enter Valid Input.</p>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group position-relative labelwarp">
                    <label for="">Work Mode</label>
                    <ngx-select-dropdown formControlName="work_mode" [options]="workModeMaster" (change)="getWorkMode($event)" class="form-control p-0 border-0" [config]="{search: true, placeholder: 'Choose One', 'height': '200px'}" [multiple]="false"></ngx-select-dropdown>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group position-relative labelwarp">
                    <label for="">Job Type</label>
                    <ngx-select-dropdown formControlName="job_type" [options]="jobTypeMaster" (change)="getJobType($event)" class="form-control p-0 border-0" [config]="{search: true, placeholder: 'Choose One', 'height': '200px'}" [multiple]="false"></ngx-select-dropdown>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group position-relative labelwarp">
                    <label for="">Shift</label>
                    <ngx-select-dropdown formControlName="shift" [options]="shiftMaster" (change)="getShift($event)" class="form-control p-0 border-0" [config]="{search: true, placeholder: 'Choose One', 'height': '200px'}" [multiple]="false"></ngx-select-dropdown>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group position-relative labelwarp">
                    <label for="">Job Location</label>
                    <input type="text" placeholder="" class="form-control" formControlName="job_location">
                    <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'job_location', 'required')">This Field is Required</p>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group position-relative labelwarp">
                    <label for="">Closing Date</label>
                    <input type="date" placeholder="" class="form-control" formControlName="close_date">
                    <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'close_date', 'required')">This Field is Required</p>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group position-relative labelwarp">
                    <label for="">Job Description</label>
                    <!-- <textarea name="" id="" class="form-control" formControlName="jobDescription"></textarea> -->
                    <div class="NgxEditor__Wrapper smeditor">
                    <ngx-editor-menu [editor]="editor" [toolbar]="Global.toolbar"> </ngx-editor-menu>
                    <ngx-editor [editor]="editor" formControlName="job_description" [required]="true" placeholder=""></ngx-editor>
                </div>
                <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'job_description', 'required')">This Field is Required</p>
                  </div>
                </div>
               
                
              </div>
            </form>
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group position-relative labelwarp">
                  <button type="button" class="btn button-solide mt-3" (click)="submit($event)">Create</button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 ps-md-5">
              <!-- <owl-carousel-o></owl-carousel-o> -->
            <ng-container *ngIf="templateList?.length !== 0; else showthis">
            <owl-carousel-o [options]="customOptions" class="requisitionslider">
            
              <ng-container *ngFor="let data of templateList">
                <ng-template carouselSlide>
                  <label class="slide-label">
                    <input type="radio" id="slide" name="req_slider" value="Slide1" 
                    (change)="onTemplateSelect(data.id)" >
                    <div class="slidepro">
                      <h2>{{data?.name}}</h2>
                      <div class="procelist">
                        <ul class="procelist-item">
                          <h5> Defined Approval Process</h5>
                          <ng-container *ngFor="let item of data?.approval; let i = index">
                            <li>
                              <!-- <span class="grecheck"><i class="bi bi-check-lg"></i></span> -->
                              <span class="greencheck">
                                <span class="checkbox-container pe_check"><i class="bi bi-exclamation"></i></span>
                              </span>
                              <p><span>STAGE {{i+1}}</span></p>
                              <h2>{{item?.stage_title}}</h2>
                              <p>{{item?.approver_name ?? ""}}</p>
                            </li>
                          </ng-container>
                        </ul>
                        <ul class="procelist-item">
                          <h5> Defined Interview Process</h5>
                          <ng-container *ngFor="let item of data?.interview; let i = index">
                            <li>
                              <!-- <span class="grecheck"><i class="bi bi-check-lg"></i></span> -->
                              <span class="greencheck">
                                <span class="checkbox-container gr_num"> {{i + 1}}</span>
                              </span>
                              <p><span>STAGE {{i+1}}</span></p>
                              <h2>{{item?.stage_title}}</h2>
                              <h6>{{item?.interviewer_name ?? ""}}</h6>
                              <p>{{item?.stage_desc ?? ""}}</p>
                            </li>
                          </ng-container>
                        </ul>
                      </div>
                    </div>
                  </label>
                </ng-template>
               </ng-container> 
               
            </owl-carousel-o>
            </ng-container>
            <ng-template #showthis>
              <h4>No Template Found For This Department.</h4>
            </ng-template>
          </div>  
          </div>           
        </div>
        
      </div>
    </div>
  
    <div class="info-fluid height100 cr-rq-edit" *ngIf="this.operation == 'edit'">
      <div class="card info_box">
        <div class="p-15 d-flex flex-wrap align-items-center">
          <div class="headstu">
              <h5 class="mb-0 me-4">Requistion Form</h5>
            <!-- <span class="hedtag">Interview In-Progress</span>
            <button class="btn button_action  ms-4"><i class="bi bi-pencil-fill"></i></button>  -->
          </div>        
          <!-- <button class="btn button-solide border-btn ms-auto"><i class="bi bi-check"></i> Synched with <i class="bi bi-linkedin"></i></button> -->
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <form [formGroup]="requistionForm">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group position-relative labelwarp">
                      <label for="">Department</label>
                      <ngx-select-dropdown style="background-color: #ffffff;" formControlName="department" 
                      [options]="departmentsMaster" class="form-control p-0 border-0"[config]="{ search: true, placeholder: 'Select One' }">
                      </ngx-select-dropdown>
                      <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'department', 'required')">This Field is Required</p>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group position-relative labelwarp">
                      <label for="">Date</label>
                      <input type="date" placeholder="" class="form-control" formControlName="date">
                      <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'date', 'required')">This Field is Required</p>
                    </div>
                  </div>
                
                  <div class="col-lg-6">
                    <div class="form-group position-relative labelwarp">
                      <label for="">Company Name</label>
                      <input type="text" placeholder="" class="form-control" formControlName="company_name">
                      <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'company_name', 'required')">This Field is Required</p>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group position-relative labelwarp">
                      <label for="">Branch Name</label>
                      <ngx-select-dropdown style="background-color: #ffffff;" formControlName="branch_name" (change)="getBranch($event)"
                      [options]="companyBranchMaster" class="form-control p-0 border-0"[config]="{ search: true, placeholder: 'Select One' }">
                      </ngx-select-dropdown>
                      <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'branch_name', 'required')">This Field is Required</p>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group position-relative labelwarp">
                      <label for="">Job Title</label>
                      <input type="text" placeholder="" class="form-control" formControlName="job_title">
                      <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'job_title', 'required')">This Field is Required</p>
                      <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'job_title', 'pattern')">Enter Valid Input.</p>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group position-relative labelwarp">
                      <label for="">Positions</label>
                      <input type="text" placeholder="" class="form-control" formControlName="position">
                      <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'position', 'required')">This Field is Required</p>
                      <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'position', 'pattern')">Enter Valid Input.</p>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group position-relative labelwarp">
                      <label for="">Experience</label>
                      <input type="text" placeholder="" class="form-control" formControlName="experience">
                      <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'experience', 'required')">This Field is Required</p>
                      <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'experience', 'pattern')">Enter Valid Input.</p>
                      <span class="unit">Years</span>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group position-relative labelwarp">
                      <label for="">Salary</label>
                      <input type="text" placeholder="" class="form-control" formControlName="salary">
                      <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'salary', 'required')">This Field is Required.</p>
                      <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'salary', 'pattern')">Enter Valid Input.</p>
                      <span class="unit">AED</span>
                    </div>
                  </div>
    
                  <div class="col-lg-6">
                    <div class="form-group position-relative labelwarp">
                      <label for="">No. Of Vacancy</label>
                      <input type="text" placeholder="" class="form-control" formControlName="vacancy">
                      <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'vacancy', 'required')">This Field is Required.</p>
                      <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'vacancy', 'pattern')">Enter Valid Input.</p>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group position-relative labelwarp">
                      <label for="">Work Mode</label>
                      <ngx-select-dropdown formControlName="work_mode" [options]="workModeMaster" (change)="getWorkMode($event)" class="form-control p-0 border-0" [config]="{search: true, placeholder: 'Choose One', 'height': '200px'}" [multiple]="false"></ngx-select-dropdown>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group position-relative labelwarp">
                      <label for="">Job Type</label>
                      <ngx-select-dropdown formControlName="job_type" [options]="jobTypeMaster" (change)="getJobType($event)" class="form-control p-0 border-0" [config]="{search: true, placeholder: 'Choose One', 'height': '200px'}" [multiple]="false"></ngx-select-dropdown>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group position-relative labelwarp">
                      <label for="">Shift</label>
                      <ngx-select-dropdown formControlName="shift" [options]="shiftMaster" (change)="getShift($event)" class="form-control p-0 border-0" [config]="{search: true, placeholder: 'Choose One', 'height': '200px'}" [multiple]="false"></ngx-select-dropdown>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group position-relative labelwarp">
                      <label for="">Job Location</label>
                      <input type="text" placeholder="" class="form-control" formControlName="job_location">
                      <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'job_location', 'required')">This Field is Required</p>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group position-relative labelwarp">
                      <label for="">Closing Date</label>
                      <input type="date" placeholder="" class="form-control" formControlName="close_date">
                      <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'close_date', 'required')">This Field is Required</p>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group position-relative labelwarp">
                      <label for="">Job Description</label>
                      <!-- <textarea name="" id="" class="form-control" formControlName="jobDescription"></textarea> -->
                      <div class="NgxEditor__Wrapper smeditor">
                      <ngx-editor-menu [editor]="editor" [toolbar]="Global.toolbar"> </ngx-editor-menu>
                      <ngx-editor [editor]="editor" formControlName="job_description" [required]="true" placeholder=""></ngx-editor>
                  </div>
                  <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'job_description', 'required')">This Field is Required</p>
                    </div>
                  </div>
                  
                  
                </div>
              </form>
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group position-relative labelwarp">
                    <button type="button" class="btn button-solide mt-3" (click)="update($event)">Update</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 ps-md-5">
                <!-- <owl-carousel-o></owl-carousel-o> -->
                <ng-container *ngIf="singleTemplateData?.length !== 0; else showthis">
                  <owl-carousel-o [options]="customOptions" class="requisitionslider">
                  
                    <ng-container *ngIf="singleTemplateData">
                      <ng-template carouselSlide>
                        <label class="slide-label">
                          <!-- <input type="radio" id="data?.id" name="req_slider" value="data.id" [attr.checked]="data.id === templateId" 
                          (change)="onTemplateSelect(data.id)"  > -->
                          <div class="slidepro">
                            <h2>{{singleTemplateData?.name}}</h2>
                            <div class="procelist">
                              <ul class="procelist-item">
                                <h5> Defined Approval Process</h5>
                                <ng-container *ngFor="let item of singleTemplateData?.approval; let i = index">
                                  <li>
                                    <!-- <span class="grecheck"><i class="bi bi-check-lg"></i></span> -->
                                    <!-- <span class="greencheck">
                                      <input id="apply_terms" type="checkbox" name="apply_terms">
                                      <span class="checkbox-container"></span>
                                    </span> -->
                                    <span class="greencheck">
                                        <!-- <input id="apply_terms" type="checkbox" name="apply_terms"> -->
                                        <span class="checkbox-container gr_check" *ngIf="item?.status == 'approved'"><i class="bi bi-check-lg"></i></span>
                                        <span class="checkbox-container pe_check" *ngIf="item?.status == 'pending'" ><i class="bi bi-exclamation"></i></span>
                                        <span class="checkbox-container re_check" *ngIf="item?.status == 'rejected'" ><i class="bi bi-x"></i></span>
                                      </span>
                                    <p><span>STAGE {{i+1}}</span></p>
                                    <h2>{{item?.stage_title}}</h2>
                                    <p>{{item?.emp_first_name }}&nbsp; {{item?.emp_last_name }}</p>
                                  </li>
                                </ng-container>
                              </ul>
                              <ul class="procelist-item">
                                <h5> Defined Interview Process</h5>
                                <ng-container *ngFor="let item of singleTemplateData?.interview; let i = index">
                                  <li>
                                    <!-- <span class="grecheck"><i class="bi bi-check-lg"></i></span> -->
                                    <span class="greencheck">
                                      <!-- <input id="apply_terms" type="checkbox" name="apply_terms"> -->
                                      <span class="checkbox-container gr_num"> {{i + 1}}</span>
                                    </span>
                                    <p><span>STAGE {{i+1}}</span></p>
                                    <h2>{{item?.stage_title}}</h2>
                                    <h6>{{item?.emp_first_name}}&nbsp; {{item?.emp_last_name}}</h6>
                                    <p>{{item?.stage_desc ?? ""}}</p>
                                  </li>
                                </ng-container>
                              </ul>
                            </div>
                          </div>
                        </label>
                      </ng-template>
                      </ng-container> 
                      
                  </owl-carousel-o>
                </ng-container>
                <ng-template #showthis>
                  <h4>No Template Found For This Department.</h4>
                </ng-template>
            </div>  
          </div>           
        </div>  
      </div>
    </div>
  
     