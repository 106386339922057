
<div class="info-fluid height100 "> 
    <div class="card info_box">
      <div class="p-15 border-bottom">
        <a class="backbtn mb-3" href="javascript:void(0)" (click)="backToApprovalList()" ><i class="bi bi-chevron-left"></i> Back to My Job</a>
      </div>  
  
      <div class="card-body p-15 position-relative">
        <h2 class="pagetitle mb-3"></h2>
        <app-navbar></app-navbar>
        <div class="tab-content plain-tcontent position-relative" id="nav-tabContent">
          <div class="tab-pane fade active show" id="nav-shortlist" role="tabpanel" aria-labelledby="nav-shortlist-tab"> 
            <div class="headtable">
              <table class="table table-bordered mb-0">
                <thead>
                  <tr>
                    <td style="width: 46%; background-color: #F5F6FA;">
                      <label class="ms_container ps-0">
                        <span>Applicant Name</span>
                      </label>
                    </td>
                    <td class="static-text" style="background-color: #f5f6fa;"> Source </td>
                    <td class="static-text" style="background-color: #f5f6fa;"> Short Listed Date </td>
                    <td class="static-text" style="background-color: #f5f6fa;"> Location </td>
                    <td class="static-text" style="background-color: #f5f6fa;"> Email </td>
                    <td class="static-text" style="background-color: #f5f6fa;"> Phone </td>
                    <td class="static-text" style="background-color: #f5f6fa;">DownLoad/View CV</td>
                    <!-- <td class="static-text" style="background-color: #f5f6fa;"> Hire/Reject </td> -->
                    <!-- <td class="static-text" style="background-color: #f5f6fa;"> </td> -->
                  </tr>
                </thead>
                <tbody>
                    <ng-container *ngIf="hiredApplicantList.length; else noData">
                  <ng-container *ngFor="let data of hiredApplicantList;">
                  <tr class="ng-star-inserted">
                    <td>
                      <div class="w-100 d-flex align-items-center">
                        <div class="user_dtls d-flex align-items-center cursor">
                          <figure class="ng-star-inserted">
                            <img alt="user" [src]= "data?.imageFilepath" height="50px" width="50px" style="border-radius: 50%;">
                          </figure>
                          <figcaption>
                            <h3>{{data?.fname}} &nbsp; {{data?.lname}}</h3>
                            <!-- <h5 class="ng-star-inserted">ID:victor_34_smith</h5> -->
                          </figcaption>
                        </div>
                      </div>
                    </td>
                    <td class="static-text"> <button class="btn graybtn">Manual</button> </td>
                    <td class="static-text">{{data?.updatedAt | date:'YYYY/MM/dd'}} </td>
                    <td class="static-text"> {{data?.location}} </td>
                    <td class="static-text"> {{data?.email}}  </td>
                    <td  class="static-text">{{data?.phone}}</td>
                    <td class="btn button_action blue_action ng-star-inserted mx-auto" (click)="downloadCvFile(data?.resumeFilepath, data?.resume)"><i class="bi bi-download"></i></td>
                    
                  </tr>
                  </ng-container>
                  </ng-container>
                  <ng-template #noData>
                    <td colspan="100%" style="text-align: center;"> No Data Found.</td>
                  </ng-template>
                </tbody>
              </table>
            </div>
          </div> 
           
        </div> 
      </div>
    </div>
  </div> 
  
  
  
  
  