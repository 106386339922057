import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AtsRoutingModule } from './ats-routing.module';
import { AtsComponent } from './ats.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ApprovalListComponent } from './approval-list/approval-list.component';
import { RequisitionComponent } from './requisition/requisition.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreateRequistionComponent } from './requisition/create-requistion/create-requistion.component';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { NgxEditorModule } from 'ngx-editor';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ApplicantsComponent } from './job-details/applicants/applicants.component';
import { HiredComponent } from './job-details/hired/hired.component';
import { InterviewComponent } from './job-details/interview/interview.component';
import { NavbarComponent } from './job-details/navbar/navbar.component';
import { RejectedComponent } from './job-details/rejected/rejected.component';
import { ReviewComponent } from './job-details/review/review.component';
import { ShortlistComponent } from './job-details/shortlist/shortlist.component';
import { ViewJobDetailsComponent } from './job-details/view-job-details/view-job-details.component';
import { ApproveRejectDialogComponent } from './job-details/view-job-details/approve-reject-dialog/approve-reject-dialog.component';
import { ShortlistDialogComponent } from './job-details/shortlist/shortlist-dialog/shortlist-dialog.component';
import { InterviewStatusDialogComponent } from './job-details/interview/interview-status-dialog/interview-status-dialog.component';
import { HiringDialogComponent } from './job-details/interview/hiring-dialog/hiring-dialog.component';
import { InterviewDialogComponent } from './job-details/interview/interview-dialog/interview-dialog.component';


@NgModule({
  declarations: [
    AtsComponent,
    ApprovalListComponent,
    RequisitionComponent,
    CreateRequistionComponent,
    ApplicantsComponent,
    HiredComponent,
    InterviewComponent,
    NavbarComponent,
    RejectedComponent,
    ReviewComponent,
    ShortlistComponent,
    ViewJobDetailsComponent,
    ApproveRejectDialogComponent,
    ShortlistDialogComponent,
    InterviewStatusDialogComponent,
    HiringDialogComponent,
    InterviewDialogComponent
  ],
  imports: [
    CommonModule,
    AtsRoutingModule,
    SharedModule,
    MatTabsModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    SelectDropDownModule,
    NgxEditorModule,
    CarouselModule
  ]
})
export class AtsModule { }
