<div class="info-fluid height100">
    <div class="card info_box">
        <div class="p-15 d-flex flex-wrap align-items-center border-bottom">
            <h5 class="mb-0">{{translations?.myRequisitionsList}}</h5>
            <button class="btn button-solide ms-auto" (click)="routeToPage()" >+ {{translations?.newRequisition}}</button>
        </div>
        <div class="card-body p-0">
            <div class="p-15 d-flex">
                <div class="serch-main me-13">
                    <i class="bi bi-search"></i>
                    <!-- <input type="text" class="form-control" placeholder="Search by name, id..."> -->
                    <input type="text" class="form-control " placeholder={{translations?.searchByNameId}} [formControl]="searchkey">
                </div>
            </div>
            <div class="table_main">
                <table class="table table-bordered">
                    <tbody>
                        <tr>
                        <td>{{translations?.date}}</td>
                        <td>{{translations?.id}}</td>
                        <td>{{translations?.jobTitle}}</td>
                        <td>{{translations?.positions}}</td>
                        <td>{{translations?.department}}</td>
                        <td>{{translations?.experience}}</td>
                        <td>{{translations?.requestedBy}}</td>
                        <!-- <td>Application</td> -->
                        
                        <td>{{translations?.currentStage}}</td>
                        <!-- <td>Status</td> -->
                        <td>{{translations?.action}}</td>
                        </tr>
                        <ng-container *ngIf="this.requisitionList?.length !== 0">
                        <tr *ngFor="let data of requisitionList">                       
                        <td>{{ (data?.updatedAt ? data?.updatedAt : data?.createdAt) | date:'yyyy-MM-dd' }}</td>
                        <td>{{data?.c_id}}</td>
                        <td>{{data?.job_title}}</td>
                        <td>{{data?.position}}</td>
                        <td>{{data?.deptartment_name}}</td>
                        <td>{{(data?.experience == "" ) ? "N/A" : data?.experience}}</td>
                        <td>{{data?.createdBy?.name}}</td>
                        <!-- <td>21</td> -->
                        
                        <td>{{data?.approval_status | capitalizeFirstLetter}}</td>
                        <!-- <td>{{data?.status}}</td>    -->
                        <td>
                            <div class="d-flex align-items-center justify-content-center">
                            <button class="btn button_action blue_action" (click)="jobDetails(data)"><i class="bi bi-eye-fill"></i></button>
                            <button class="btn button_action" *ngIf="data?.isEditable == true" (click)="edit(data)"><i class="bi bi-pencil-fill"></i></button>
                            
                            <button class="btn button_action" title="Copy Link" *ngIf="data?.approval_status == 'all approved'" (click)="copyLink( data?.uniqueId)"><i class="bi bi-clipboard-minus-fill"></i></button>
                            <input hidden type="text" class="form-control" [value]="data?.uniqueId"/>
                            <!-- <button class="btn button_action" title="Generate Link" (click)="generateLink(data)"><i class="bi bi-link"></i></button> -->

                            </div>
                        </td>                     
                        </tr>
                        </ng-container>
                    
                            <tr *ngIf="this.requisitionList?.length == 0">
                                <td [attr.colspan]="8" class="text-center">{{translations?.noDataFound}}</td>
                            </tr>
                    
                        
                    </tbody>
                </table>
            </div>
            <!-- <div class="mt-0" *ngIf="requisitionList?.length !== 0">
                <app-custom-pagination [rows]="requisitionList" [paginationOptions]="paginationOptions" (onPageClicked)="fetchRequistionList($event, null)"></app-custom-pagination>
            </div> -->
        </div>
    </div>
</div>


