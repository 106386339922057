

  <div class="info-fluid height100 "> 
    <div class="card info_box">
      <div class="p-15 border-bottom">
        <a class="backbtn mb-3" href="javascript:void(0)" (click)="backToApprovalList()"><i class="bi bi-chevron-left" ></i> Back to My Job</a>
      </div>  
  
      <div class="card-body p-15 position-relative">
        <h2 class="pagetitle mb-3"></h2>
        <app-navbar></app-navbar>
        <div class="tab-content plain-tcontent position-relative" id="nav-tabContent">
           
          <div class="tab-pane fade active show" id="nav-applicants" role="tabpanel" aria-labelledby="nav-applicants-tab"> 
            <div class="headtable">
              <table class="table table-bordered mb-0">
                <thead>
                  <tr>
                    <td style="width: 30%; background-color: #F5F6FA;">
                      <label class="ms_container d-flex">
                        <input type="checkbox" [value]="rowCheckedAll" (change)="allRowsCheckboxChecked($event)"  *ngIf="showCheckbox()"  class="h-auto me-2">
                        <span class="checkmark" *ngIf="showCheckbox()"></span>
                        <span> Applicant Name</span>
                      </label>
                    </td>
                    <td class="static-text" style="background-color: #f5f6fa;"> Source </td>
                    <td class="static-text" style="background-color: #f5f6fa;"> Applied Date </td>
                    <td class="static-text" style="background-color: #f5f6fa;"> Location </td>
                    <td class="static-text" style="background-color: #f5f6fa;"> Email </td>
                    <td class="static-text" style="background-color: #f5f6fa;"> Phone </td>
                    <td class="static-text" style="background-color: #f5f6fa;"> Downlaod/View CV </td>
                   
                    <!-- <td class="static-text" style="background-color: #f5f6fa;"> </td> -->
                    <!-- <td class="static-text" style="background-color: #f5f6fa;"> </td> -->
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngIf="applicantList?.length; else noData">
                    
                  <tr class="ng-star-inserted" *ngFor="let data of applicantList; let i=index;">
                    <td>
                      <div class="w-100 d-flex align-items-center">
                        <label class="ms_container" [for]="'checkrow-' + (i + 1)" *ngIf="data?.approval_status == 'pending'">
                          <input type="checkbox" class="me-2"  
                          [attr.checked]="
                                data?.checked == true ? 'checked' : null
                                "
                                id="checkrow-{{ i + 1 }}"
                                [attr.data-checkbox-id]="data?.id"
                                (click)="rowCheckBoxChecked($event, data)"   >
                          <span class="checkmark"  ></span>
                        </label>
                        <div class="user_dtls d-flex align-items-center cursor">
                          <figure class="ng-star-inserted" *ngIf="data?.imageFilepath; else showThis">
                            <img alt="user" [src]="data?.imageFilepath">
                          </figure>
                          <ng-template #showThis>
                          <figure class="ng-star-inserted">
                            <img alt="user" src="assets/images/user-icon.jpg">
                          </figure>
                        </ng-template>
                          <figcaption>
                            <h3>{{data?.fname}} {{data?.lname}}</h3>
                            <!-- <h5 class="ng-star-inserted">ID:victor_34_smith</h5> -->
                          </figcaption>
                        </div>
                      </div>
                    </td>
                    <td class="static-text"> <button class="btn graybtn">Manual</button> </td>
                    <td class="static-text"> {{data?.createdAt | date: "YYYY/MM/dd"}} </td>
                    <td class="static-text"> {{data?.location}}</td>
                    <td class="static-text"> {{data?.email}}  </td>
                    <td  class="static-text">{{data?.phone}}</td>
                    <td  class="static-text ng-star-inserted text-center">
                      <button class="btn button_action blue_action ng-star-inserted mx-auto" (click)="downloadCvFile(data?.resumeFilepath)"><i class="bi bi-download"></i></button>
                    </td>
                   
                  </tr>
                 
                  </ng-container>
                  <ng-template #noData>
                    <tr >
                      <td [attr.colspan]="8" class="text-center">No Data Found</td>
                  </tr>
                  </ng-template>
                
                  
                </tbody>
              </table>
            </div>
            <div class="position-absolute end-0 tabbtn">
              <button type="button" class="button button-solide" [disabled]="!anyRowsChecked()" (click)="submitToReview($event)">Send for Review</button>
            </div>
          </div> 
           
        </div> 
      </div>
    </div>
  </div> 
    