
    
        <div class="p-15 border-bottom modal-header bg-light">
            <div class="modal-text">
                <h3 class="derktext left-line mb-0 static-text">Interview Schedule Status</h3>
            </div>
            <button class="btn-close btn ms-4 ms-auto"><i class="bi bi-x-lg" (click)="closeDialog()"></i></button>
        </div>
   
        <div class="card-body p-15 position-relative">
            <!-- <h2 class="pagetitle mb-3"></h2> -->
            <div class="tab-content plain-tcontent position-relative" id="nav-tabContent">
               
              <div class="tab-pane fade active show" id="nav-applicants" role="tabpanel" aria-labelledby="nav-applicants-tab"> 
                <div class="headtable">
                  <table class="table table-bordered mb-0">
                    <thead>
                      <tr>
                        <td style="width: 30%; background-color: #F5F6FA;">
                          <label class="ms_container d-flex">
                            <span> Interviewer Name</span>
                          </label>
                        </td>
                        <td class="static-text" style="background-color: #f5f6fa;"> Stage Title </td>
                        <td class="static-text" style="background-color: #f5f6fa;"> Date </td>
                        <td class="static-text" style="background-color: #f5f6fa;"> Location </td>
                        <td class="static-text" style="background-color: #f5f6fa;"> Status </td>
                       
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngIf="interviewDetails?.length; else noData">
                        
                      <tr class="ng-star-inserted" *ngFor="let data of interviewDetails; let i=index;">
                        <td>
                          <div class="w-100 d-flex align-items-center">
                           
                            <div class="user_dtls d-flex align-items-center cursor">
                              <figcaption>
                                <h3>{{data?.interviewerDetails?.emp_first_name}} &nbsp; {{data?.interviewerDetails?.emp_last_name}}</h3>
                                <!-- <h5 class="ng-star-inserted">ID:victor_34_smith</h5> -->
                              </figcaption>
                            </div>
                          </div>
                        </td>
                        <td class="static-text"> {{data?.stage_title ?? "N/A"}} </td>
                        <td class="static-text"> {{(data?.date | date: "YYYY/MM/dd") ?? "N/A"}} </td>
                        <td class="static-text"> {{data?.location ?? "N/A"}}</td>
                        <td class="static-text"> {{(data?.place_status | capitalizeFirstLetter) ?? "N/A"}}  </td>
                        
                       
                      </tr>
                     
                      </ng-container>
                      <ng-template #noData>
                        <tr >
                          <td [attr.colspan]="8" class="text-center">No Data Found</td>
                      </tr>
                      </ng-template>
                    
                      
                    </tbody>
                  </table>
                </div>
                
              </div> 
               
            </div> 
          </div>