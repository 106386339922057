import { HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import PaginationOptions from 'src/app/model/PaginationOptions';
import { AtsService } from 'src/app/services/ats.service';
import * as Global from 'src/app/shared/globals';


@Component({
  selector: 'app-rejected',
  templateUrl: './rejected.component.html',
  styleUrls: ['./rejected.component.css']
})
export class RejectedComponent {
  requistionId:any;
  hiredApplicantList:any[] = [];
  paginationOptions: PaginationOptions = Global.resetPaginationOption();
  constructor(
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private atsService: AtsService,
    private toastr: ToastrService,
    private router: Router
    
  ){

  }

  ngOnInit(): void {
    this.spinner.show();
    this.route.params.subscribe(params => {
      console.log(params)
      this.requistionId = params['id'] ?? null;
      console.log(this.requistionId)
  })
  this.fetchHiredApplicants(this.paginationOptions?.page)

}

fetchHiredApplicants(page: number) {
  return new Promise((resolve, reject) => {
    this.atsService.applicantForInterview(
      {
        params: new HttpParams()
        .set('page', page?.toString() || '1') 
        .set('limit', '10')
        
      }, this.requistionId
    ).subscribe((res: any) => {
       console.log(res)
      const data = res?.data ?? {};
       console.log(data)
      if (res?.message == "List interviews") {
         data.map((result:any)=>{
          console.log(result)
          if(result?.hiring_status === 'rejected'){
            this.hiredApplicantList.push(result);
          }
         })
         console.log(this.hiredApplicantList)
        
  
        this.spinner.hide();
        this.paginationOptions = {
          page: data?.page,
          limit: data?.limit,
          hasNextPage: data?.hasNextPage,
          hasPrevPage: data?.hasPrevPage,
          nextPage: data?.nextPage,
          prevPage: data?.prevPage,
          totalDocs: data?.totalItems,
          totalPages: data?.totalPages,
          pagingCounter: data?.pagingCounter
        }
        resolve(true);
      } else {
        this.toastr.error(res?.message);
        resolve(false);
      }
    })
  })
}
downloadCvFile(filepath:any, filename:any){
  this.atsService.downloadFileDirectly(filepath, filename);
}

backToApprovalList(){
  console.log("kjhfkj")
  this.router.navigate(['en', 'ats', 'approval-list' ])
}
}
