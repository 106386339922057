import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AtsService } from 'src/app/services/ats.service';
import * as Global from 'src/app/shared/globals';

@Component({
  selector: 'app-hiring-dialog',
  templateUrl: './hiring-dialog.component.html',
  styleUrls: ['./hiring-dialog.component.css']
})
export class HiringDialogComponent {
  hiringForm: FormGroup;
  hiringStatusMaster: any[] = [];


  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<HiringDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private atsService: AtsService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ){
    this.hiringForm = this.formBuilder.group({
      // applicant_name: ['', Validators.compose([Validators.required])],
      hiring_status: ['', Validators.compose([Validators.required])],
      remarks: ['', Validators.compose([Validators.required])],
     
    });

    this.hiringStatusMaster = [
      { 'value': 'hired', 'description': 'Hired' },
      { 'value': 'rejected', 'description': 'Rejected' },
    ]

  }

  ngOnInit(): void {
    console.log(this.data)
  }

  async selectApplicant(event: any) {
    this.hiringForm.markAllAsTouched();
    Global.scrollToQuery('.form-control.is-invalid');
    if (this.hiringForm.valid) {
    
      event.target.classList.add('btn-loading');
      event.target.disabled = true;
      this.atsService.hiringAppicant({
        applicant_id: this.data?.applicantData?.id,
        hiring_status: this.hiringForm?.value?.hiring_status.value ?? "",
        remarks: this.hiringForm.value?.remarks ?? ""
    }).subscribe(
        (res: any) => {
            console.log('Response:', res);
            if (res?.status === 200) {
                this.toastr.success(res?.message);
                this.dialogRef.close(true);
            }
        },
        (err: any) => {
            console.error('Error Object:', err); 
            const errorMessage =
                err?.error?.error?.message?.message ||
                'An unknown error occurred.';
            
            this.toastr.error(errorMessage);
    
            event.target.classList.remove('btn-loading');
            event.target.disabled = false;
        }
    );
    
      
      
    }
  }

  closeDialog(){
    this.dialogRef.close();
  }

}
