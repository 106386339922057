
<!-- <div class="info-fluid height100">
    <div class="card info_box edit_sec">
      <div class="p-15 d-flex flex-wrap align-items-center">
        <div class="headstu">
            <h5 class="mb-0 me-4">Approval Request Form</h5>
         
        </div>        
        
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <form [formGroup]="requistionForm">
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group position-relative labelwarp">
                    <label for="">Department</label>
                    <ngx-select-dropdown style="background-color: #ffffff;" formControlName="department" 
                    [options]="departmentsMaster" class="form-control p-0 border-0"[config]="{ search: true, placeholder: 'Select One' }">
                    </ngx-select-dropdown>
                    <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'department', 'required')">This Field is Required</p>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group position-relative labelwarp">
                    <label for="">Date</label>
                    <input type="date" placeholder="" class="form-control" formControlName="date">
                    <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'date', 'required')">This Field is Required</p>
                  </div>
                </div>
              
                <div class="col-lg-6">
                  <div class="form-group position-relative labelwarp">
                    <label for="">Company Name</label>
                    <input type="text" placeholder="" class="form-control" formControlName="company_name">
                    <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'company_name', 'required')">This Field is Required</p>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group position-relative labelwarp">
                    <label for="">Branch Name</label>
                    <ngx-select-dropdown style="background-color: #ffffff;" formControlName="branch_name" 
                    [options]="companyBranchMaster" class="form-control p-0 border-0"[config]="{ search: true, placeholder: 'Select One' }">
                    </ngx-select-dropdown>
                    <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'branch_name', 'required')">This Field is Required</p>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group position-relative labelwarp">
                    <label for="">Job Title</label>
                    <input type="text" placeholder="" class="form-control" formControlName="job_title">
                    <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'job_title', 'required')">This Field is Required</p>
                    <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'job_title', 'pattern')">Enter Valid Input.</p>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group position-relative labelwarp">
                    <label for="">Positions</label>
                    <input type="text" placeholder="" class="form-control" formControlName="position">
                    <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'position', 'required')">This Field is Required</p>
                    <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'position', 'pattern')">Enter Valid Input.</p>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group position-relative labelwarp">
                    <label for="">Experience</label>
                    <input type="text" placeholder="" class="form-control" formControlName="experience">
                    <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'experience', 'required')">This Field is Required</p>
                    <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'experience', 'pattern')">Enter Valid Input.</p>
                    <span class="unit">Years</span>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group position-relative labelwarp">
                    <label for="">Salary</label>
                    <input type="text" placeholder="" class="form-control" formControlName="salary">
                    <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'salary', 'required')">This Field is Required.</p>
                    <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'salary', 'pattern')">Enter Valid Input.</p>
                    <span class="unit">AED</span>
                  </div>
                </div>
  
                <div class="col-lg-6">
                  <div class="form-group position-relative labelwarp">
                    <label for="">No. Of Vacancy</label>
                    <input type="text" placeholder="" class="form-control" formControlName="vacancy">
                    <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'vacancy', 'required')">This Field is Required.</p>
                    <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'vacancy', 'pattern')">Enter Valid Input.</p>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group position-relative labelwarp">
                    <label for="">Work Mode</label>
                    <ngx-select-dropdown formControlName="work_mode" [options]="workModeMaster" (change)="getWorkMode($event)" class="form-control p-0 border-0" [config]="{search: true, placeholder: 'Choose One', 'height': '200px'}" [multiple]="false"></ngx-select-dropdown>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group position-relative labelwarp">
                    <label for="">Job Type</label>
                    <ngx-select-dropdown formControlName="job_type" [options]="workModeMaster" (change)="getJobType($event)" class="form-control p-0 border-0" [config]="{search: true, placeholder: 'Choose One', 'height': '200px'}" [multiple]="false"></ngx-select-dropdown>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group position-relative labelwarp">
                    <label for="">Shift</label>
                    <ngx-select-dropdown formControlName="shift" [options]="workModeMaster" (change)="getShift($event)" class="form-control p-0 border-0" [config]="{search: true, placeholder: 'Choose One', 'height': '200px'}" [multiple]="false"></ngx-select-dropdown>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group position-relative labelwarp">
                    <label for="">Job Location</label>
                    <input type="text" placeholder="" class="form-control" formControlName="job_location">
                    <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'job_location', 'required')">This Field is Required</p>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group position-relative labelwarp">
                    <label for="">Closing Date</label>
                    <input type="date" placeholder="" class="form-control" formControlName="close_date">
                    <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'close_date', 'required')">This Field is Required</p>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group position-relative labelwarp">
                    <label for="">Job Description</label>
                    
                    <div class="NgxEditor__Wrapper smeditor">
                    <ngx-editor-menu [editor]="editor" [toolbar]="Global.toolbar"> </ngx-editor-menu>
                    <ngx-editor [editor]="editor" formControlName="job_description" [required]="true" placeholder=""></ngx-editor>
                </div>
                <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'job_description', 'required')">This Field is Required</p>
                  </div>
                </div>

                <div class="col-12">
                    <div class="form-group position-relative labelwarp">
                      <label for="">Remarks</label>
                      <textarea name="" id="" class="form-control" formControlName="remarks"></textarea>
                                       </div>
                  <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'remarks', 'required')">This Field is Required</p>
                    </div>
                  
                
                
              </div>
            </form>
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group position-relative labelwarp">
                  <button type="button" class="btn button-solide mt-3" (click)="update($event)">Approve</button>
                  <button type="button" class="btn button-solide mt-3" (click)="update($event)">Reject</button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 ps-md-5">
             
            <ng-container *ngIf="requistionData?.length !== 0; else showthis">
              <owl-carousel-o [options]="customOptions" class="requisitionslider">
              
                <ng-container *ngIf="requistionData">
                  <ng-template carouselSlide>
                    <label class="slide-label">
                     
                      <div class="slidepro">
                        <h2>{{requistionData?.template_name}}</h2>
                        <div class="procelist">
                          <ul class="procelist-item">
                            <h5> Defined Approval Process</h5>
                            <ng-container *ngFor="let item of requistionData?.req_approval; let i = index">
                              <li>
                               
                                <span class="greencheck">
                                    
                                  <span class="checkbox-container gr_check" *ngIf="item?.status == 'approved'"><i class="bi bi-check-lg"></i></span>
                                    <span class="checkbox-container pe_check" *ngIf="item?.status == 'pending'" ><i class="bi bi-exclamation"></i></span>
                                    <span class="checkbox-container re_check" *ngIf="item?.status == 'rejected'" ><i class="bi bi-x"></i></span>
                                  </span>
                                <p><span>STAGE {{i+1}}</span></p>
                                <h2>{{item?.stage_title}}</h2>
                                <p>{{item?.approver_det?.emp_first_name }}&nbsp; {{item?.approver_det?.emp_last_name }}</p>
                              </li>
                            </ng-container>
                          </ul>
                          <ul class="procelist-item">
                            <h5> Defined Interview Process</h5>
                            <ng-container *ngFor="let item of requistionData?.req_interview; let i = index">
                              <li>
                                
                                <span class="greencheck">
                                 
                                  <span class="checkbox-container gr_num"> {{i + 1}}</span>
                                </span>
                                <p><span>STAGE {{i+1}}</span></p>
                                <h2>{{item?.stage_title}}</h2>
                                <h6>{{item?.interviewer_det?.emp_first_name}}&nbsp; {{item?.interviewer_det?.emp_last_name}}</h6>
                                <p>{{item?.stage_desc ?? ""}}</p>
                              </li>
                            </ng-container>
                          </ul>
                        </div>
                      </div>
                    </label>
                  </ng-template>
                  </ng-container> 
                  
              </owl-carousel-o>
            </ng-container>
            <ng-template #showthis>
              <h4>No Template Found For This Department.</h4>
            </ng-template>
          </div>  
        </div>           
      </div>  
    </div>
  </div> -->

  <div class="modalmain app-rq-form">
    <div class="container">
        <div class="row align-items-center justify-content-center j">
            <div class="col-lg-12 p-0">
                <div class="boxshodo">
                    <div class="p-15 modal-header bg-light">
                        <div class="modal-text">
                            <h3 class="derktext left-line mb-0 flip-element">Approval Request Form</h3>
                        </div>
                        <button class="btn-close btn ms-auto" mat-dialog-close ><i
                                class="bi bi-x-lg"></i></button>
                    </div>
                </div>
                <div class="boxshodo mb-0 borderredius-0">
                    <div class="p-15 modal-body py-4 border-bottom">
                        <div class="risection">
                            <div class="row">
                              <div class="col-md-6">
                                <form [formGroup]="requistionForm">
                                  <div class="row">
                                    <div class="col-lg-6">
                                      <div class="form-group position-relative labelwarp">
                                        <label for="">Department</label>
                                        <ngx-select-dropdown style="background-color: #ffffff;" formControlName="department" 
                                        [options]="departmentsMaster" class="form-control p-0 border-0"[config]="{ search: true, placeholder: 'Select One' }">
                                        </ngx-select-dropdown>
                                        <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'department', 'required')">This Field is Required</p>
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group position-relative labelwarp">
                                        <label for="">Date</label>
                                        <input type="date" placeholder="" class="form-control" formControlName="date">
                                        <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'date', 'required')">This Field is Required</p>
                                      </div>
                                    </div>
                                  
                                    <div class="col-lg-6">
                                      <div class="form-group position-relative labelwarp">
                                        <label for="">Company Name</label>
                                        <input type="text" placeholder="" class="form-control" formControlName="company_name">
                                        <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'company_name', 'required')">This Field is Required</p>
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group position-relative labelwarp">
                                        <label for="">Branch Name</label>
                                        <ngx-select-dropdown style="background-color: #ffffff;" formControlName="branch_name" (change)="getBranch($event)"
                                        [options]="companyBranchMaster" class="form-control p-0 border-0"[config]="{ search: true, placeholder: 'Select One' }">
                                        </ngx-select-dropdown>
                                        <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'branch_name', 'required')">This Field is Required</p>
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group position-relative labelwarp">
                                        <label for="">Job Title</label>
                                        <input type="text" placeholder="" class="form-control" formControlName="job_title">
                                        <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'job_title', 'required')">This Field is Required</p>
                                        <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'job_title', 'pattern')">Enter Valid Input.</p>
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group position-relative labelwarp">
                                        <label for="">Positions</label>
                                        <input type="text" placeholder="" class="form-control" formControlName="position">
                                        <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'position', 'required')">This Field is Required</p>
                                        <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'position', 'pattern')">Enter Valid Input.</p>
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group position-relative labelwarp">
                                        <label for="">Experience</label>
                                        <input type="text" placeholder="" class="form-control" formControlName="experience">
                                        <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'experience', 'required')">This Field is Required</p>
                                        <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'experience', 'pattern')">Enter Valid Input.</p>
                                        <span class="unit">Years</span>
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group position-relative labelwarp">
                                        <label for="">Salary</label>
                                        <input type="text" placeholder="" class="form-control" formControlName="salary">
                                        <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'salary', 'required')">This Field is Required.</p>
                                        <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'salary', 'pattern')">Enter Valid Input.</p>
                                        <span class="unit">AED</span>
                                      </div>
                                    </div>
                      
                                    <div class="col-lg-6">
                                      <div class="form-group position-relative labelwarp">
                                        <label for="">No. Of Vacancy</label>
                                        <input type="text" placeholder="" class="form-control" formControlName="vacancy">
                                        <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'vacancy', 'required')">This Field is Required.</p>
                                        <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'vacancy', 'pattern')">Enter Valid Input.</p>
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group position-relative labelwarp">
                                        <label for="">Work Mode</label>
                                        <ngx-select-dropdown formControlName="work_mode" [options]="workModeMaster" (change)="getWorkMode($event)" class="form-control p-0 border-0" [config]="{search: true, placeholder: 'Choose One', 'height': '200px'}" [multiple]="false"></ngx-select-dropdown>
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group position-relative labelwarp">
                                        <label for="">Job Type</label>
                                        <ngx-select-dropdown formControlName="job_type" [options]="workModeMaster" (change)="getJobType($event)" class="form-control p-0 border-0" [config]="{search: true, placeholder: 'Choose One', 'height': '200px'}" [multiple]="false"></ngx-select-dropdown>
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group position-relative labelwarp">
                                        <label for="">Shift</label>
                                        <ngx-select-dropdown formControlName="shift" [options]="workModeMaster" (change)="getShift($event)" class="form-control p-0 border-0" [config]="{search: true, placeholder: 'Choose One', 'height': '200px'}" [multiple]="false"></ngx-select-dropdown>
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group position-relative labelwarp">
                                        <label for="">Job Location</label>
                                        <input type="text" placeholder="" class="form-control" formControlName="job_location">
                                        <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'job_location', 'required')">This Field is Required</p>
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group position-relative labelwarp">
                                        <label for="">Closing Date</label>
                                        <input type="date" placeholder="" class="form-control" formControlName="close_date">
                                        <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'close_date', 'required')">This Field is Required</p>
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group position-relative labelwarp">
                                        <label for="">Job Description</label>
                                        <!-- <textarea name="" id="" class="form-control" formControlName="jobDescription"></textarea> -->
                                        <div class="NgxEditor__Wrapper smeditor">
                                        <ngx-editor-menu [editor]="editor" [toolbar]="Global.toolbar"> </ngx-editor-menu>
                                        <ngx-editor [editor]="editor" formControlName="job_description" [required]="true" placeholder=""></ngx-editor>
                                    </div>
                                    <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'job_description', 'required')">This Field is Required</p>
                                      </div>
                                    </div>
                    
                                    <div class="col-12">
                                        <div class="form-group position-relative labelwarp">
                                          <label for="">Remarks</label>
                                          <textarea name="" id="" class="form-control" formControlName="remarks"></textarea>
                                                           </div>
                                      <p class="error-element" *ngIf="!Global.isInputRuleValid(requistionForm, 'remarks', 'required')">This Field is Required</p>
                                        </div>
                                      
                                    
                                    
                                  </div>
                                </form>
                                <div class="row">
                                  <div class="col-lg-6">
                                    <div class="form-group position-relative labelwarp">
                                      <button type="button" class="btn button-solide mt-3" (click)="update($event, 'approved')">Approve</button>&nbsp;&nbsp;
                                      <button type="button" class="btn btn-danger mt-3" (click)="update($event, 'rejected')">Reject</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-6 ps-md-5">
                                  <!-- <owl-carousel-o></owl-carousel-o> -->
                                <ng-container *ngIf="requistionData?.length !== 0; else showthis">
                                  <owl-carousel-o [options]="customOptions" class="requisitionslider">
                                  
                                    <ng-container *ngIf="requistionData">
                                      <ng-template carouselSlide>
                                        <label class="slide-label">
                                          <div class="slidepro">
                                            <h2>{{requistionData?.template_name}}</h2>
                                            <div class="procelist">
                                              <ul class="procelist-item">
                                                <h5> Defined Approval Process</h5>
                                                <ng-container *ngFor="let item of requistionData?.req_approval; let i = index">
                                                  <li>
                                                    <span class="greencheck">
                                                        <!-- <input id="apply_terms" type="checkbox" name="apply_terms"> -->
                                                        <span class="checkbox-container gr_check" *ngIf="item?.status == 'approved'"><i class="bi bi-check-lg"></i></span>
                                                        <span class="checkbox-container pe_check" *ngIf="item?.status == 'pending'" ><i class="bi bi-exclamation"></i></span>
                                                        <span class="checkbox-container re_check" *ngIf="item?.status == 'rejected'" ><i class="bi bi-x"></i></span>
                                                      </span>
                                                    <p><span>STAGE {{i+1}}</span></p>
                                                    <h2>{{item?.stage_title}}</h2>
                                                    <p>{{item?.approver_det?.emp_first_name }}&nbsp; {{item?.approver_det?.emp_last_name }}</p>
                                                  </li>
                                                </ng-container>
                                              </ul>
                                              <ul class="procelist-item">
                                                <h5> Defined Interview Process</h5>
                                                <ng-container *ngFor="let item of requistionData?.req_interview; let i = index">
                                                  <li>
                                                    <!-- <span class="grecheck"><i class="bi bi-check-lg"></i></span> -->
                                                    <span class="greencheck">
                                                      <!-- <input id="apply_terms" type="checkbox" name="apply_terms"> -->
                                                      <span class="checkbox-container gr_num"> {{i + 1}}</span>
                                                    </span>
                                                    <p><span>STAGE {{i+1}}</span></p>
                                                    <h2>{{item?.stage_title}}</h2>
                                                    <h6>{{item?.interviewer_det?.emp_first_name}}&nbsp; {{item?.interviewer_det?.emp_last_name}}</h6>
                                                    <p>{{item?.stage_desc ?? ""}}</p>
                                                  </li>
                                                </ng-container>
                                              </ul>
                                            </div>
                                          </div>
                                        </label>
                                      </ng-template>
                                      </ng-container> 
                                      
                                  </owl-carousel-o>
                                </ng-container>
                                <ng-template #showthis>
                                  <h4>No Template Found For This Department.</h4>
                                </ng-template>
                              </div>  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>