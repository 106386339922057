<div class="info-fluid height100 w-75 mx-auto shortlist-dialog">
  <div class="modal-content">
    <div class="modal-header bg-white">

        <div class="d-flex align-items-center flex-wrap gap-1 justify-content-between w-100">
          <div class="d-flex jobdtls-top align-items-center">
            <ng-container *ngIf="this.applicantDetails?.imageFilepath; else showThis">
            <figure class="mb-0"><img alt="" [src]="this.applicantDetails?.imageFilepath" class="ng-star-inserted">
            </figure>
            </ng-container>
            <ng-template #showThis>
            <figure class="mb-0"><img alt="" src="https://dev14.ivantechnology.in/cartesian/public/no_logo.png" class="ng-star-inserted">
            </figure>
            </ng-template>
            <div>
              <!-- <p><span>ID: 8511</span></p> -->
              <h3>{{this.applicantDetails?.fname}}&nbsp; {{this.applicantDetails?.lname}}</h3>
            </div>
          </div>
          <button type="button" class="btn close p-0" data-bs-dismiss="modal" aria-label="Close" (click)="closeDialog()">
            <i class="bi bi-x"></i>
          </button>
        </div>
      
    </div>
    <div class="modal-body bg-white p-15 pt-0">
      <hr class="mt-0 mb-4">
      <div class="row">
        <div class="col-md-7">
          <div class="d-flex align-items-center flex-wrap gap-1 mb-4">
            <!-- <div class="profilebox ms-0 me-2">
              <div class="d-flex jobdtls-top">
                <figure ><img alt="" src="https://dev14.ivantechnology.in/cartesian/public/no_logo.png" class="ng-star-inserted"></figure>
                <div>
                  <p><span>ID: victor_34_smith</span></p>
                  <h4>Victor thirty four Naser Smith</h4>
                </div>
              </div>
            </div> -->
            <div class="thbox align-items-center">
              <h6 class="mb-0 mt-1">Name</h6>
              <p>{{this.applicantDetails?.fname}}&nbsp; {{this.applicantDetails?.lname}}</p>
              <br>
              <h6 class="mb-0 mt-1">Email</h6>
              <p>{{this.applicantDetails?.email}}</p>
              <br>
              <h6 class="mb-0 mt-1">Phone</h6>
              <p>{{this.applicantDetails?.phone}}</p>
              <br>
              <h6 class="mb-0 mt-1">Address</h6>
              <p>{{this.applicantDetails?.location}}</p>  
            </div>
          </div>
          <hr>
          <div class="bradius4 border-gray">
            <div class="d-flex justify-content-between align-items center border w-100 p-2">
              <h6 class="darkblue mb-0 font14 ">
                <i class="bi bi-file-earmark-check-fill"></i> 
                 <!-- <img src="assets/images/pdf-green.png" alt=""> -->
                Cv.doc
              </h6>
              <a href="" target="_blank" class="bluedownload font14 blue text-decoration-none flex-shrink-0 ms-2" (click)="downloadCvFile(this.applicantDetails?.resumeFilepath)">
                <i class="bi bi-download"></i> Download 
              </a>
            </div>
          </div>
        </div>
        <div class="col-md-5 scrollpopup ps-lg-5">
          <!-- Form -->
            <div class="d-flex align-items-center mb-2">
              <h3 class="subhead font16 fw-bold me-auto mb-0">Interview Schedule</h3>
             
            </div>
            <form [formGroup]="interviewSheduleForm">
              <ng-container formArrayName="interview_template">
                <ng-container *ngFor="let data of interviewTempList; let i = index" [formGroupName]="i">
            <div class="border bradius8 p-3 mb-3">
              <h3 class="subhead font16 fw-bold me-auto mb-2">Interview Stage {{i + 1}}</h3>
              <div class="form-list position-relative d-flex align-items-center justify-content-between">
                
                <h3 class="subhead mb-0 fw-medium font15">Interviewer Name</h3>
                <input type="text" class="inputgray form-control" formControlName="interviewer_name" readonly>
                
                <!-- <div class="invalid-feedback"> This filed is required. </div> -->

              </div>
              
              <div class="form-list position-relative d-flex align-items-center justify-content-between">
              
                <h3 class="subhead mb-0 fw-medium font15">Interview Title</h3>
                <input type="text" class="inputgray form-control" formControlName="interview_title">
                
                <div class="invalid-feedback"> This filed is required. </div>

              </div>
              
              <div class="form-list position-relative d-flex align-items-center justify-content-between">
                
                <h3 class="subhead mb-0 fw-medium font15">Location</h3>
                
                <input type="text" class="inputgray form-control" formControlName="location">
                
                <div class="invalid-feedback"> This filed is required. </div>
              
            </div>
              <div class="form-list position-relative d-flex align-items-center justify-content-between">
                
                <h3 class="subhead mb-0 fw-medium font15">Date</h3>
                
                <input type="date" class="inputgray form-control" formControlName="date"  placeholder="Date">
                <div class="invalid-feedback"> This filed is required. </div>
              
            </div>
              <div class="form-list position-relative d-flex align-items-center justify-content-between">
                
                <h3 class="subhead mb-0 fw-medium font15">Time</h3>
                
                
                <input type="time" class="inputgray form-control" formControlName="time">
                
                <div class="invalid-feedback"> This filed is required. </div>
              
            </div>
              

              <!-- <div class="form-list position-relative d-flex align-items-center justify-content-between">
                
                <h3 class="subhead mb-0 fw-medium font15">Remarks</h3>
                <textarea class="inputgray form-control" style="height: 100px;" formControlName="remarks" ></textarea>
                
                <div class="invalid-feedback"> This filed is required. </div>
              </div> -->


              <hr>
              <div class="form-list-btn d-flex justify-content-end">
                <ng-container *ngIf="data.isUserScheduleInterview && data?.place_status == 'pending'">
                <button href="javascript:void(0)" class="btn button-solide btn-border" (click)="closeDialog()">Cancel</button>
               
                <button class="button button-solide ms-2" type="submit"  (click)="updateTemplate($event, data, i)">Submit</button>
              </ng-container>
              </div>
            </div>
            </ng-container>
          </ng-container>
        </form>
        </div>
      </div>
    </div>
  </div>
</div>
