<nav>
    <div class="design-tnav nav-tabs brdrtb mb-3 pb-3" id="nav-tab" role="tablist">
        <button class="tlink" [class.active]="isActive('job-details')"  id="nav-jobdtls-tab" data-bs-toggle="tab" data-bs-target="#nav-jobdtls" type="button" role="tab" aria-controls="nav-jobdtls" aria-selected="true" (click)="routerChange('job-details')">Job Details</button>
        <button class="tlink" [class.active]="isActive('applicants')" id="nav-applicants-tab" data-bs-toggle="tab" data-bs-target="#nav-applicants" type="button" role="tab" aria-controls="nav-applicants" aria-selected="false" (click)="routerChange('applicants')">Applicants </button> 
        <button class="tlink" [class.active]="isActive('review')" id="nav-review-tab" data-bs-toggle="tab" data-bs-target="#nav-review" type="button" role="tab" aria-controls="nav-review" aria-selected="false" (click)="routerChange('review')">Review </button> 
        <button class="tlink" [class.active]="isActive('shortlist')" id="nav-shortlist-tab" data-bs-toggle="tab" data-bs-target="#nav-shortlist" type="button" role="tab" aria-controls="nav-shortlist" aria-selected="false" (click)="routerChange('shortlist')">Shortlisted </button> 
        <button class="tlink" [class.active]="isActive('interview')" id="nav-interview-tab" data-bs-toggle="tab" data-bs-target="#nav-interview" type="button" role="tab" aria-controls="nav-interview" aria-selected="false" (click)="routerChange('interview')">Interview </button> 
        <button class="tlink" [class.active]="isActive('hired')" id="nav-hire-tab" data-bs-toggle="tab" data-bs-target="#nav-hire" type="button" role="tab" aria-controls="nav-hire" aria-selected="false" (click)="routerChange('hired')">Hired </button> 
        <button class="tlink" [class.active]="isActive('rejected')" id="nav-reject-tab" data-bs-toggle="tab" data-bs-target="#nav-reject" type="button" role="tab" aria-controls="nav-reject" aria-selected="false" (click)="routerChange('rejected')">Rejected</button>  
    </div>
</nav>