import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-interview-status-dialog',
  templateUrl: './interview-status-dialog.component.html',
  styleUrls: ['./interview-status-dialog.component.css']
})
export class InterviewStatusDialogComponent implements OnInit {
  interviewDetails: any[] = [];
  constructor(
    private dialogRef: MatDialogRef<InterviewStatusDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ){

  }

  ngOnInit(): void {
    this.interviewDetails = this.data?.interviewData?.interviews ?? [];
    console.log(this.interviewDetails)
  }

  closeDialog(){
    this.dialogRef.close();
  }

}
