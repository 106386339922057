import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AtsService } from 'src/app/services/ats.service';
import { ApproveRejectDialogComponent } from './approve-reject-dialog/approve-reject-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-view-job-details',
  templateUrl: './view-job-details.component.html',
  styleUrls: ['./view-job-details.component.css']
})
export class ViewJobDetailsComponent {
  requistionId:any;
  requistionData:any;
  singleTemplateData:any;
  employeeMaster!:any[];
  employeeDetails:any;
  employeeData:any;
  approveFlag:boolean;

  constructor(
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private atsService: AtsService,
    private toastr: ToastrService,
    private dialogRef: MatDialog,
    private router:Router
  ){}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      console.log(params)
      this.requistionId = params['id'] ?? null;
      console.log(this.requistionId)
      this.fetchMaster();
      this.fetchRequistionDetails();
    })

    let comData:any=(localStorage.getItem('employee-data'));
    
    console.log(JSON.parse(comData))
    this.employeeData = JSON.parse(comData)
    
  }

  fetchMaster(){
    return new Promise<any>((resolve, reject) => {
       this.spinner.show();
      this.atsService.getMasterList().subscribe((res: any) => {
        console.log(res, "master");
        if (res?.status == 200) {
          // this.departmentsMaster = [];
          // if (res?.data?.departments && Array.isArray(res?.data?.departments)) {
          //   res?.data?.departments.forEach((element: any) => {
          //         this.departmentsMaster.push({ id: element.id, description: `${element.description}`, master_id: element?.master_id, name:`${element.name}` })
          //     });
          // }
          this.employeeMaster = [];
          if (res?.data?.employees && Array.isArray(res?.data?.employees)) {
            res?.data?.employees.forEach((element: any) => {
              //  console.log(element)
                  this.employeeMaster.push({ id: element.id, description:`${element.emp_first_name +" "+ 
                    element.emp_last_name
                  }`, value:`${element.emp_first_name +" " + element.emp_last_name}` })
              });
          }
          console.log(this.employeeMaster)
          // console.log(this.departmentsMaster)
          // this.departmentsMaster = res?.data?.departments ?? [];
          resolve(true);
          this.spinner.hide();
        } else {
          this.spinner.hide();
          this.toastr.error(res?.message);
          resolve(false);
        }
      })
    });
  }

  fetchRequistionDetails(){
    return new Promise<any>((resolve, reject) => {
       this.spinner.show();
      this.atsService.getSingleRequistion(this.requistionId).subscribe((res: any) => {
        // console.log(res, "tttt");
        if (res?.message == 'Get single requistion data') {
          this.requistionData = res?.data?.listData ?? {};
          // console.log(this.requistionData)
        this.requistionData?.req_approval.map((obj:any)=>{
          // console.log(obj?.approver_det?.master_id)
          // console.log(this.employeeData?._id)
          if((obj?.approver_det?.master_id === this.employeeData?._id)){
            // console.log(obj)
            if( obj?.status == "pending"){
            // console.log(true)
            this.approveFlag = true;
          }else{
            // console.log(false)
            this.approveFlag = false;
          }
        }
        })
          
          this.fetchTempDetails();
          
          resolve(true);
          this.spinner.hide();
        } else {
          this.spinner.hide();
          this.toastr.error(res?.message);
          resolve(false);
        }
      })
    });
  }

  fetchTempDetails(){
    return new Promise<any>((resolve, reject) => {
       this.spinner.show();
      this.atsService.getTempDetails(this.requistionData?.template_id).subscribe((res: any) => {
        console.log(res, "tttt");
        if (res?.status == 200) {
          this.singleTemplateData = res?.data?.listData ?? {};
          console.log(this.singleTemplateData)
          
          resolve(true);
          this.spinner.hide();
        } else {
          this.spinner.hide();
          this.toastr.error(res?.message);
          resolve(false);
        }
      })
    });
  }

  openDialog(){
    
      this.dialogRef.open(ApproveRejectDialogComponent, {
        maxWidth: '1000px',
        maxHeight: '100vh',
        width: '100%',
        height: 'auto',
        panelClass: 'full-screen-modal',
        data: {requition_id: this.requistionId},
      }).afterClosed().subscribe((res: any) => {
        if(res == true){
          this.fetchRequistionDetails();
        }
      });
    
  }

  backToApprovalList(){
    console.log("kjhfkj")
    this.router.navigate(['en', 'ats', 'approval-list' ])
  }

}
