import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AtsService } from 'src/app/services/ats.service';
import * as Global from 'src/app/shared/globals';

@Component({
  selector: 'app-interview-dialog',
  templateUrl: './interview-dialog.component.html',
  styleUrls: ['./interview-dialog.component.css']
})
export class InterviewDialogComponent {
  interviewForm: FormGroup;
  qualifyStatusMaster: any[] = [];
  interview_id:any;


  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<InterviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private atsService: AtsService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ){
    this.interviewForm = this.formBuilder.group({
      // applicant_name: ['', Validators.compose([Validators.required])],
      qualify_status: ['', Validators.compose([Validators.required])],
      remarks: ['', Validators.compose([Validators.required])],
     
    });

    this.qualifyStatusMaster = [
      { 'value': 'qualified', 'description': 'Qualified' },
      { 'value': 'disqualified', 'description': 'Disqualified' },
    ]

  }

  ngOnInit(): void {
    console.log(this.data);
    this.atsService.fetchAccountDetails({}).then((res:any)=>{
      console.log(res)
      this.data?.interviewData?.interviews.map((obj:any)=>{
        if(obj?.interviewerDetails?.master_id === res?.employee_data?._id){
          console.log(obj?.id)
          return this.interview_id = obj?.id;
        }
      })
    })
  }

  async selectApplicant(event: any) {
    this.interviewForm.markAllAsTouched();
    Global.scrollToQuery('.form-control.is-invalid');
    if (this.interviewForm.valid) {
    
      event.target.classList.add('btn-loading');
      event.target.disabled = true;
      this.atsService.interviewApplicant({
        interview_id: this.interview_id,
        qualify_status: this.interviewForm?.value?.qualify_status.value ?? "",
        remarks: this.interviewForm.value?.remarks ?? ""
    }).subscribe(
        (res: any) => {
            console.log('Response:', res);
            if (res?.status === 200) {
                this.toastr.success(res?.message);
                this.dialogRef.close(true);
            }
        },
        (err: any) => {
            console.error('Error Object:', err); 
            const errorMessage =
                err?.error?.error?.message?.message ||
                'An unknown error occurred.';
            
            this.toastr.error(errorMessage);
    
            event.target.classList.remove('btn-loading');
            event.target.disabled = false;
        }
    );
    
      
      
    }
}

closeDialog(){
  this.dialogRef.close();
}
}
